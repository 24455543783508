import { gql } from "@apollo/client";

const SEND_MESSAGE = gql`
  mutation sendMessage($from: String!, $hospital_id: String!, $text: String!, $patient_id: String!) {
    insert_messages_one(object: {from: $from, hospital_id: $hospital_id, text: $text, patient_id: $patient_id}) {
      text
    }
  }
`;

export default SEND_MESSAGE;

import { gql } from "@apollo/client"

const MESSAGE_ADDED_SUBSCRIPTION = gql`
    subscription messageAdded($patientId: String!, $from:String!){
        messages(where: {patient_id: {_eq: $patientId}, _and: {from: {_eq: $from}}}, limit: 1, order_by: {created_at: desc}) {
            id
            created_at
            text
            hospital_id
            patient_id
            from
        }
    }
`

export default MESSAGE_ADDED_SUBSCRIPTION;

import { gql } from "@apollo/client";

export default gql`
  query staffs {
    staffs {
      id
      email
      name
      phoneNumber
    }
  }
`;

export const REMOVE_STAFF_FROM_PATIENT = gql`
  mutation RemoveStaffFromPatients($staffId: uuid!) {
    update_patients(
      where: { staff_id: { _eq: $staffId } }
      _set: { staff_id: null }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_STAFFS = gql`
  mutation MyMutation($staff_id: uuid!) {
    delete_staffs(where: { id: { _eq: $staff_id } }) {
      returning {
        id
      }
    }
  }
`;

export const REMOVE_STAFF_FROM_MEDICINES = gql`
  mutation RemoveStaffFromMedicines($staffId: uuid!) {
    update_medicines(
      where: { staff_id: { _eq: $staffId } }
      _set: { staff_id: null }
    ) {
      affected_rows
    }
  }
`;

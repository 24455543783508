// import "./RegisterView.css";
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../firebase/use-auth";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

const SIGNUP_PHARMACY_MUTATION = gql`
  mutation signUpPharmacy(
    $pillName: String!
    $pillType: String!
    $thumbnail: String!
    $hospital_id: String!
  ) {
    insert_pharmacy_one(
      object: {
        pillName: $pillName
        pillType: $pillType
        thumbnail: $thumbnail
        hospital_id: $hospital_id
      }
    ) {
      id
    }
  }
`;

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const resizeImage = async (base64Str, maxWidth, maxHeight) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      let x = 0;
      let y = 0;
      let w = width;
      let h = height;
      if (width > height) {
        if (width > MAX_WIDTH) {
          w *= MAX_HEIGHT / height;
          h = MAX_HEIGHT;
          x = -(w - MAX_WIDTH) / 2;
        }
      } else {
        if (height > MAX_HEIGHT) {
          w = MAX_WIDTH;
          h *= MAX_WIDTH / width;
          y = -(h - MAX_HEIGHT) / 2;
        }
      }

      canvas.width = MAX_WIDTH;
      canvas.height = MAX_HEIGHT;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, x, y, w, h);
      resolve(canvas.toDataURL());
    };
  });
};

const SignupSchema = Yup.object().shape({
  pillName: Yup.string().required("กรุณากรอกชื่อยา"),
  pillType: Yup.string().required("กรุณากรอกชนิดยา"),
});

const initialValues = {
  pillName: "",
  pillType: "วัณโรค",
  thumbnail: "",
  hospitalId: "",
};

const RegisterForm = () => {
  const { t } = useTranslation();

  const drop = useRef(null);
  const auth = useAuth();
  const [signUpPharmacy] = useMutation(SIGNUP_PHARMACY_MUTATION);
  const [pillImage, setPillImage] = useState("");
  const setTableUpdated = useStore((state) => state.setTableUpdated);

  const history = useHistory();

  const handleUpload = async (event) => {
    if (event.target.files[0]) {
      let image = event.target.files[0];
      image = URL.createObjectURL(image);
      image = await getBase64FromUrl(image);
      image = await resizeImage(image, 200, 200);
      setPillImage(image);
    }
  };

  const onSubmit = (values) => {
    signUpPharmacy({
      variables: {
        pillName: values.pillName,
        pillType: values.pillType,
        thumbnail: pillImage,
        hospital_id: auth.user?.uid,
      },
    }).then(
      () => {
        setSubmitting(false);
        setTableUpdated(false);
        history.goBack();
      },
      (error) => {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
        window.alert(
          "[เกิดข้อผิดพลาด] ข้อมูลไม่ถูกต้องหรือข้อมูลนี้มีอยู่แล้วในระบบ โปรดตรวจสอบ !"
        );
        resetForm();
      }
    );
  };

  const {
    setSubmitting,
    setErrors,
    resetForm,
    isSubmitting,
    handleSubmit,
    handleChange,
    values,
  } = useFormik({
    validationSchema: SignupSchema,
    initialValues,
    onSubmit,
  });

  return (
    <div className="app" ref={drop}>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <label>
          <span>{t("register_pharmacy.form.pill_name")}</span>
          <input
            type="text"
            name="pillName"
            onChange={handleChange}
            value={values.pillName}
          />
        </label>

        <label>
          <span>{t("register_pharmacy.form.pill_type")}</span>
          <select
            className="dropdown"
            component="select"
            name="pillType"
            onChange={handleChange}
          >
            <option value="วัณโรค">
              {t("register_pharmacy.form.tuberculosis")}
            </option>
            <option value="วัณโรคดื้อยา">
              {t("register_pharmacy.form.drug_resistant_tuberculosis")}
            </option>
          </select>
        </label>

        <label>
          <span>{t("register_pharmacy.form.pill_image")}</span>
          <input type="file" onChange={handleUpload} />
        </label>

        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={pillImage} alt="" id={"canvas"} />
        </div>

        <button className="mybutton" type="submit" disabled={isSubmitting}>
          {isSubmitting
            ? t("register_pharmacy.submit.loading")
            : t("register_pharmacy.submit.register")}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;

import React, { useRef } from "react";
import "./VideoReport.css"


const VideoReporter = (props) => {
  const vidRef = useRef(null);

  const playVideo = () => {
    vidRef.current.play();
  }

  if (props.type === "video/mp4")
    return (
        <div className="video-wrapper">
          <video
              ref={vidRef}
              style={{ height: "440px" }}
              autoPlay={true}
              src={props.src}
              type={"video/mp4"}
          />
          <div>
            <button className="overlay-button"  onClick={playVideo}>
              play_circle_outline
            </button>
          </div>

          <div className="options"  >
            <label className="checkbox10">
              <input
                  type="radio" name={props.videoId} id={props.videoId}
                  key={props.videoId} value="approved"
                  checked={props.approveItem === "approved"}
                  onChange={props.handler}
              />
              <img alt=""/>
            </label>

            <label className="checkbox20">
              <input type="radio" name={props.videoId}  id={props.videoId}
                     key={props.videoId}  value="denied"
                     checked={props.approveItem === "denied"}
                     onChange={props.handler}
              />
              <img  alt=""/>
            </label>

          </div>
        </div>
    );
  if (props.type === "image/jpeg") {
    return (
        <div className="container2">

          <div className="video2">
            <img style={{ height: "440px" }} alt="รูปภาพยา" src={props.src} />
          </div>

          <div className="options"  >
            <label className="checkbox10">
              <input
                  type="radio" name={props.videoId} id={props.videoId}
                  key={props.videoId} value="approved"
                  checked={props.approveItem === "approved"}
                  onChange={props.handler}
              />
              <img alt="" />
            </label>

            <label className="checkbox20">
              <input type="radio" name={props.videoId}  id={props.videoId}
                     key={props.videoId} value="denied"
                     checked={props.approveItem === "denied"}
                     onChange={props.handler}
              />
              <img alt=""/>
            </label>

          </div>

        </div>

    );
  }
  else return null;
}

export default VideoReporter;
import React from "react";
import NurseList from "../components/NurseList";
import { useTranslation } from 'react-i18next';

const App = () => {

  const { t } = useTranslation();

  return (
    <div id="main">

      <div style={{
        backgroundColor: 'white',
        borderColor: 'white',
        borderBlockWidth: 0,
        fontSize: 30,
        padding: 10,
        fontFamily: 'Kanit',
      }}>
        {t('nurses_list.topic')}
      </div>
      <div style={{ padding: 10 }} />
      <NurseList />

    </div>
  )
}


export default App;

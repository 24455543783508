// import "./RegisterView.css";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStore } from "../../store";
import { useTranslation } from 'react-i18next';

import { gql } from "@apollo/client"

const UPDATE_PHARMACY = gql`
mutation updatePharmacy($id: uuid!, $pillName: String!, $pillType: String, $thumbnail: String) {
  update_pharmacy_by_pk(pk_columns: {id: $id}, _set: {pillName: $pillName, pillType: $pillType, thumbnail: $thumbnail}) {
    id
  }
}`;

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;   
      resolve(base64data);
    }
  });
}

const resizeImage = async(base64Str, maxWidth, maxHeight) => {
  return new Promise((resolve) => {
    let img = new Image()
    img.src = base64Str
    img.onload = () => {
      let canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      let x = 0;
      let y = 0;
      let w = width;
      let h = height;
      if (width > height) {
        if (width > MAX_WIDTH) {
          w *= MAX_HEIGHT / height;
          h = MAX_HEIGHT;
          x = -(w - MAX_WIDTH) / 2;
        }
      } else {
        if (height > MAX_HEIGHT) {
          w = MAX_WIDTH;
          h *= MAX_WIDTH / width;
          y = -(h - MAX_HEIGHT) / 2;
        }
      }

      canvas.width = MAX_WIDTH
      canvas.height = MAX_HEIGHT
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, x, y, w, h)
      resolve(canvas.toDataURL())
    }
  })
}


const SignupSchema = Yup.object().shape({
  pillName: Yup.string().required("กรุณากรอกชื่อยา"),
  pillType: Yup.string().required("กรุณากรอกชนิดยา"),
});

const PharmacyInformationView = ({ pharmacy }) => {

  const { t } = useTranslation();
  
  const drop = useRef(null);

  const [updatePharmacy] = useMutation(UPDATE_PHARMACY);
  const setTableUpdated = useStore(state => state.setTableUpdated);
  const [pillImage, setPillImage] = useState("")
  const history = useHistory();

  useEffect(() => {
    if (pharmacy.thumbnail) {
      setPillImage(pharmacy.thumbnail);
    }
  }, [pharmacy.thumbnail]);

  const handleUpload = async(event) => {
    if (event.target.files[0]) {
      let image = event.target.files[0];
      image = URL.createObjectURL(image);
      image = await getBase64FromUrl(image);
      image = await resizeImage(image, 200, 200)
      setPillImage(image)
    }
  }

  const initialValues = {
    pillName: pharmacy.pillName,
    pillType: pharmacy.pillType,
  };

  const onSubmit = async (values) => {
    try {

      await updatePharmacy({
        variables: {
          id: pharmacy?.id,
          pillName: values.pillName,
          pillType: values.pillType,
          thumbnail: pillImage,
        },
      });
      
      setSubmitting(false);
      setTableUpdated(false);
      history.goBack();
    } catch (error) {
      toast(error.message, { type: "error" });
      setSubmitting(false);
      setErrors(error);
      window.alert(
        "[เกิดข้อผิดพลาด] ข้อมูลไม่ถูกต้องหรือข้อมูลนี้มีอยู่แล้วในระบบ โปรดตรวจสอบ !"
      );
      resetForm();
    }
  };

  const {
    setSubmitting,
    setErrors,
    resetForm,
    isSubmitting,
    handleSubmit,
    handleChange,
    values
  } = useFormik({
    validationSchema: SignupSchema,
    initialValues,
    onSubmit,
  })

  return (
      <div className="app" ref={drop}>
        <ToastContainer />

        <form onSubmit={handleSubmit}>
            
          <label>
            <span>{t('pharmacy_information.form.pill_name')}</span>
            <input
              type="text"
              name="pillName"
              onChange={handleChange}
              value={values.pillName}
            />
          </label>
        
          <label>
            <span>{t('pharmacy_information.form.pill_type')}</span>
            <select className="dropdown" component="select" 
              name="pillType" onChange={handleChange}
              value={values.pillType}
            >
              <option value="วัณโรค">
                {t('pharmacy_information.form.tuberculosis')}
              </option>
              <option value="วัณโรคดื้อยา">
                {t('pharmacy_information.form.drug_resistant_tuberculosis')}
              </option>
            </select>
          </label>
          
          <label>
            <span>{t('pharmacy_information.form.pill_image')}</span>
            <input type="file" onChange={handleUpload} />
          </label>

          <div style={{ 
            width: '100%', 
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: 'center'
          }}>
            <img
              src={pillImage} 
              alt=""
              id={"canvas"}
            />
          </div>

          <button
              className="mybutton"
              type="submit"
              disabled={isSubmitting}
          >
            {isSubmitting 
              ? t('pharmacy_information.submit.loading') 
              : t('pharmacy_information.submit.edit')
            }
          </button>

        </form>

      </div>
  );
};

export default PharmacyInformationView;

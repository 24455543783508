import { gql } from "@apollo/client";

export default gql`
  query messagesStaff($patient_id: String!) {
    messages(where: {patient_id: {_eq: $patient_id}}, limit: 100, order_by: {created_at: desc}) {
      id
      text
      hospital_id
      patient_id
      from
      created_at
    }
  }
  `

export const MESSAGES_PATIENT_QUERY =  gql`
  query messagesStaff($patient_id: String!, $from: String!) {
    messages(where: {patient_id: {_eq: $patient_id} _and: {from: {_eq: $from}}}, limit: 100, order_by: {created_at: desc}) {
      id
      text
      hospital_id
      patient_id
      from
      created_at
    }
  }
`

// import "./RegisterView.css";
import React, { useRef } from "react";
import { FormikProvider, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SIGNUP_STAFF_MUTATION from "../../queries/signUpStaff";
import { useAuth } from "../../../firebase/use-auth";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("กรุณากรอกชื่อ"),
  phoneNumber: Yup.string()
    .matches(new RegExp("[0-9]{7}"), "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
    .required("กรุณากรอกเบอร์โทรศัพท์"),
  email: Yup.string()
    .email("รูปแบบอีเมลไม่ถูกต้อง")
    .required("กรุณากรอกที่อยู่อีเมล"),
});

const initialValues = {
  name: "",
  phoneNumber: "",
  email: "",
  hospitalId: "",
};

const RegisterForm = () => {
  const { t } = useTranslation();

  const drop = useRef(null);
  const auth = useAuth();
  const [signUpStaff] = useMutation(SIGNUP_STAFF_MUTATION);
  const setTableUpdated = useStore((state) => state.setTableUpdated);

  const history = useHistory();

  const onSubmit = (values) => {
    signUpStaff({
      variables: {
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        hospital_id: auth.user?.uid,
      },
    }).then(
      () => {
        setSubmitting(false);
        setTableUpdated(false);
        history.goBack();
      },
      (error) => {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
        window.alert(
          "[เกิดข้อผิดพลาด] ข้อมูลไม่ถูกต้องหรือข้อมูลนี้มีอยู่แล้วในระบบ โปรดตรวจสอบ !"
        );
        resetForm();
      }
    );
  };

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues,
    onSubmit,
  });

  const {
    setSubmitting,
    setErrors,
    resetForm,
    isSubmitting,
    handleSubmit,
    errors,
  } = formik;

  return (
    <div className="app" ref={drop}>
      <ToastContainer />

      <FormikProvider value={formik}>
        <Form>
          <label>
            <span style={{ display: "inline-flex" }}>
              {t("register_nurse.form.name_surname")}
              <div style={{ color: "red", marginLeft: 10 }}>{errors.name}</div>
            </span>
            <Field name="name" placeholder="" />
          </label>

          <label>
            <span style={{ display: "inline-flex" }}>
              {t("register_nurse.form.telephone")}
              <div style={{ color: "red", marginLeft: 10 }}>
                {errors.phoneNumber}
              </div>
            </span>
            <Field name="phoneNumber" placeholder="" />
          </label>

          <label>
            <span style={{ display: "inline-flex" }}>
              {t("register_nurse.form.email")}
              <div style={{ color: "red", marginLeft: 10 }}>{errors.email}</div>
            </span>
            <Field name="email" placeholder="" />
          </label>

          <button
            className="mybutton"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting
              ? t("register_nurse.submit.loading")
              : t("register_nurse.submit.register")}
          </button>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default RegisterForm;

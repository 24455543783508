import { gql } from "@apollo/client"

export default gql`
  query patient($id:uuid!) {
    patients_by_pk(id:$id) {
      id
      citizenId
      firebase_id
      hnId
      passport
      name
      surname
      email
      password
      result
      staff_id
      hospital
      phoneNumber
      gender
      healthConditions
      tbType
      age
      fcmToken
      medicines {
        id
        patient_id
        hospital_id
        pharmacy_id
        pillAmount
        pillName
        pillType
        staff_id
        startDate
        stopDate
      }
      notiTimes {
        id
        medicine_id
        patient_id
        time
      }
      files {
        bucket_id
        created_at
        dateStatus
        filename
        id
        mimeType
        patient_id
        hospital_id
        status
        takenAt
      }
    }
  }
`
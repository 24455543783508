import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const Input = (props) => {

  const { t } = useTranslation();

  const [text, setText] = useState('');

  const onChange = (e) => {
    setText(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setText("");
    props.onSendMessage(text);
  }

  return (
    <div className="Input">
      <form className="from inline" onSubmit={e => onSubmit(e)}>
        <input
          onChange={e => onChange(e)}
          value={text}
          type="text"
          placeholder={t('chat.place_holder')}
          autoFocus={true}
        />
        <button className="Message-button">send</button>
      </form>
    </div>
  );
}

export default Input;

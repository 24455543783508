import { Component } from "react";
import React from "react";


const weekNames = [
  'อา.', 'จ.', 'อ.',
  'พ.', 'พฤ.', 'ศ.',
  'ส.',
]
const monthNames = [
  'ม.ค.', 'ก.พ.', 'มี.ค.',
  'เม.ย.', 'พ.ค.', 'มิ.ย.',
  'ก.ค.', 'ส.ค.', 'ก.ย.',
  'ต.ค.', 'พ.ย.', 'ธ.ค.'
]
function getDateStamp(s) {
  var day = s.getDay();
  var date = s.getDate();
  var month = s.getMonth();
  var text = weekNames[day] + ' ' + date + ' ' + monthNames[month]
  return text;
}

function getTimeStamp(s) {
  var hour = s.getHours();
  var minute = ('0' + s.getMinutes()).slice(-2);
  return hour + ':' + minute + ' น.';
}

class Messages extends Component {
  componentDidMount() {
    if (this.newData) {
      this.newData.scrollIntoView();
    }
    this.props.subscribeToMore();
  }
  componentDidUpdate() {
    if (this.newData) {
      this.newData.scrollIntoView();
    }
  }

  renderMessage(message) {
    const { id, text, from, timeStamp, dateStamp, firstShow } = message;
    const className =
      from === "staff" ? "Messages-message currentMember" : "Messages-message";
    return (
      <li key={id} ref={ref => (this.newData = ref)}>
        {firstShow &&
          <div style={{textAlign: 'center', padding: 20, color: 'skyblue'}}>{dateStamp}</div>
        }
        <div className={className}>
          <div className="Message-content">
            <div className="text">{text}</div>
          </div>
          <div style={{alignSelf: 'flex-end', padding: 5}}>{timeStamp}</div>
        </div>
      </li>
    );
  }

  render() {
    const { messages } = this.props;
    const chats = messages.map((chat) => chat).reverse();
    const extendMessages = (data) => {
      let showedDates = []
      return data.map((item) => {
        const {created_at} = item
        var timeStamp = '';
        var dateStamp = '';
        var firstShow = false;
        if (typeof created_at === 'string' || created_at instanceof String) {
          const date = new Date(created_at);
          timeStamp = getTimeStamp(date);
          dateStamp = getDateStamp(date);
          firstShow = !showedDates.includes(dateStamp)
          showedDates.push(dateStamp);
        }
        return ({
          ...item,
          timeStamp: timeStamp,
          dateStamp: dateStamp,
          firstShow: firstShow
        })
      });
    }
    const messages2 = extendMessages(chats);

    return (
      <ul className="Messages-list">
        {messages2.map(m => this.renderMessage(m))}
      </ul>
    );
  }
}

export default Messages;

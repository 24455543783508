// https://react.i18next.com/guides/quick-start
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en.json';
import translationTH from './locales/th.json';

// the translations
const resources = {
  en: { translation: translationEN },
  th: { translation: translationTH }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "th",
    interpolation: { escapeValue: false }
  })

  export default i18n;
import gql from "graphql-tag";

// export default gql`
//   mutation updatePatient(
//     $citizenId: String!
//     $passport: String
//     $hnId: String
//     $name: String!
//     $surname: String
//     $hospital: String
//     $result: String
//     $phonenumber: String
//     $age: Int!
//     $gender: String!
//     $healthConditions: String
//     $tbType: String
//     $medicines: [MedicineInput]
//     $staff: StaffInput
//   ) {
//     updatePatient(
//       citizenId: $citizenId
//       hnId: $hnId
//       passport: $passport
//       name: $name
//       surname: $surname
//       hospital: $hospital
//       result: $result
//       phonenumber:$phonenumber
//       age: $age
//       gender: $gender
//       healthConditions: $healthConditions
//       tbType: $tbType
//       medicines: $medicines
//       staff: $staff
//     ) {
//       citizenId
//     }
//   }
// `;

export const UPDATE_PATIENT_MUTATION = gql`
  mutation updatePatient(
    $id: uuid!,
    $age: Int!,
    $citizenId: String!,
    $gender: String!,
    $healthConditions: String,
    $hnId: String,
    $name: String!,
    $hospital: String,
    $passport: String,
    $phoneNumber: String,
    $result: String,
    $surname: String,
    $tbType: String,
    $staff_id: uuid
    ) {
    update_patients_by_pk(
      pk_columns: {id: $id},
      _set: {
        citizenId: $citizenId,
        gender: $gender,
        healthConditions: $healthConditions,
        hnId: $hnId,
        name: $name,
        surname: $surname,
        tbType: $tbType,
        result: $result,
        hospital: $hospital,
        phoneNumber: $phoneNumber,
        passport: $passport,
        staff_id: $staff_id,
        age: $age }) {
          id
    }
  }
`;

export const UPDATE_PATIENT_MEDICINES_MUTATION = gql`
  mutation updatePatientMedicines($patient_id: String!, $medicines: [medicines_insert_input!]!) {
    delete_medicines(
      where: {patient_id: {_eq: $patient_id}}
    ) {
      affected_rows
    }
    insert_medicines(
      objects: $medicines
    ) {
      affected_rows
    }
  }
`;

import { gql } from "@apollo/client";


export const CREATE_BUCKET_MUTATION = gql`
    mutation createBucket($bucketId: String!) {
        createBucket(bucketId: $bucketId) {
            success
        }
    }`;

export const GET_UPLOAD_LINK_MUTATION = gql`
    mutation getUploadLink($bucketId: String!, $fileName: String!) {
        getUploadLink(bucketId: $bucketId, fileName: $fileName) {
            url
        }
    }
`;

export const GET_DOWNLOAD_LINK_MUTATION = gql`
    mutation getDownloadLink($bucketId: String!, $fileName: String!) {
        getDownloadLink(bucketId: $bucketId, fileName: $fileName) {
            url
        }
    }
`;

export const GET_UPLOAD_LINKS_MUTATION = gql`
    mutation getUploadLinks($bucketId: String!, $fileNames: [String!]!) {
        getUploadLinks(bucketId: $bucketId, fileNames: $fileNames) {
            urls
        }
    }
`;

export const GET_DOWNLOAD_LINKS_MUTATION = gql`
    mutation getDownloadLinks($bucketId: String!, $fileNames: [String!]!) {
        getDownloadLinks(bucketId: $bucketId, fileNames: $fileNames) {
            urls
        }
    }
`;

export const REMOVE_OBJECT_MUTATION = gql`
    mutation removeObject($bucketId: String!, $fileName: String!) {
        removeObject(bucketId: $bucketId, fileName: $fileName) {
            success
        }
    }
`;
import { gql } from '@apollo/client'

export default gql`
  mutation removeContent($bucketId: String!, $fileName: String!) {
    removeObject(bucketId: $bucketId, fileName: $fileName) {
      success
    }
    delete_files(where: {bucket_id: {_eq: $bucketId}, _and: {filename: {_eq: $fileName}}}) {
      affected_rows
    }
  }
`;

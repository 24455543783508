import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Typography, Stack, IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { ApolloProvider } from "@apollo/client";
import { useApolloClient } from "../firebase/ApolloClient";

import {
  DefaultPage,
  DashboardPage,
  PatientsTablePage,
  StaffsTablePage,
  PharmacyTablePage,
  ContentPage,
  LoginPage,
  PatientPage,
  StaffPage,
  PharmacyPage,
  RegisterNewPatientPage,
  VideoReporterPage,
  ChatPage,
  RegisterNewStaffPage,
  RegisterNewPharmacyPage,
} from "./pages";

import { useAuth } from "../firebase/use-auth";

import colors from "./utilities/colors";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserNurse,
  faPills,
  faBookMedical,
  faLanguage,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
// import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { PrivateRoute } from "../firebase/AuthContext";
import { purge } from "./store";
import { useTranslation } from "react-i18next";
import useBreakpoints from "./utilities/useBreakpoint";


const NavSide = (props) => {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const point = useBreakpoints();

  return (
    <SideNav
      style={{
        backgroundColor: colors.primary,
        // height: 100,
        width: 10,
      }}
      expanded={props.expanded}
      onToggle={props.setExpanded}
      onSelect={async (selected) => {
        var to = "";
        if (selected.startsWith("nav-")) {
          to = "/" + selected.replace("nav-", "");
          if (props.location.pathname !== to) {
            props.history.push(to);
            if (point === "xs") {
              props.setExpanded(false);
            }
          }
        } else if (selected.startsWith("lang-")) {
          to = selected.replace("lang-", "");
          i18n.changeLanguage(to);
        } else if (selected === "logout") {
          await auth.signout();
          props.history.push("/login");
          purge();
        }
      }}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected="item-patient">
        {/* <NavItem eventKey="nav-dashboard" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faChartBar} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>
            {t('nav.dashboard')}
          </NavText>
        </NavItem> */}
        <NavItem eventKey="nav-patients" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faUser} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>{t("nav.patients_list")}</NavText>
        </NavItem>
        <NavItem eventKey="nav-staffs" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faUserNurse} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>{t("nav.nurses_list")}</NavText>
        </NavItem>
        <NavItem eventKey="nav-pharmacy" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faPills} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>{t("nav.pharmacy_list")}</NavText>
        </NavItem>
        <NavItem eventKey="nav-information" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faBookMedical} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>{t("nav.tb_information")}</NavText>
        </NavItem>
        <div style={{ height: 20 }} />
        <NavItem eventKey="language" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faLanguage} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>{t("nav.change_langiage")}</NavText>
          <NavItem eventKey="lang-th" style={{ padding: 3 }}>
            <NavText style={{ fontSize: 16, paddingLeft: 40 }}>ภาษาไทย</NavText>
          </NavItem>
          <NavItem eventKey="lang-en" style={{ padding: 3 }}>
            <NavText style={{ fontSize: 16, paddingLeft: 40 }}>English</NavText>
          </NavItem>
        </NavItem>
        <NavItem eventKey="logout" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faSignOut} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>{t("nav.signout")}</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

const NavTop = (props) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      style={{
        backgroundColor: colors.primary,
        height: 60,
        color: "white",
        fontSize: 30,
      }}
    >
      <IconButton
        style={{ color: "white", padding: 20 }}
        onClick={() => {
          props.setExpanded(true);
        }}
      >
        <Menu sx={{ fontSize: 27 }} />
      </IconButton>
      <Typography
        style={{
          fontWeight: 600,
          fontSize: 24,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        TBVDOT
      </Typography>
    </Stack>
  );
};

const MainApp = () => {
  const auth = useAuth();
  const [expanded, setExpanded] = useState(false);
  const point = useBreakpoints();

  const client = useApolloClient();

  return (
    <ApolloProvider client={client}>
      <Router>
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              <div style={{ height: "100vh" }}>
                {auth.user && (
                  <>
                    {point === "xs" && (
                      <NavTop expanded={expanded} setExpanded={setExpanded} />
                    )}
                    {(point !== "xs" || expanded) && (
                      <NavSide
                        expanded={expanded}
                        setExpanded={setExpanded}
                        location={location}
                        history={history}
                      />
                    )}
                  </>
                )}

                <div
                  style={
                    auth.user
                      ? {
                          marginLeft: expanded ? 240 : point === "xs" ? 0 : 64,
                          padding: "15px 50px 0 20px",
                          height: "100vh",
                          overflowY: "scroll",
                        }
                      : {}
                  }
                >
                  <Route path="/login" exact component={LoginPage} />

                  <PrivateRoute
                    path="/"
                    exact
                    component={DefaultPage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/dashboard"
                    component={DashboardPage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/information"
                    component={ContentPage}
                    auth={auth}
                  />

                  <PrivateRoute
                    path="/patients"
                    component={PatientsTablePage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/patient_registeration"
                    component={RegisterNewPatientPage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/patient/:patientId"
                    component={PatientPage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/chat/:patientId"
                    component={ChatPage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/uploads/:patientId/:date"
                    component={VideoReporterPage}
                    auth={auth}
                  />

                  <PrivateRoute
                    path="/staffs"
                    component={StaffsTablePage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/staff_registeration"
                    component={RegisterNewStaffPage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/staff/:staffId"
                    component={StaffPage}
                    auth={auth}
                  />

                  <PrivateRoute
                    path="/pharmacy"
                    exact
                    component={PharmacyTablePage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/pharmacy_registeration"
                    component={RegisterNewPharmacyPage}
                    auth={auth}
                  />
                  <PrivateRoute
                    path="/pharmacy/:pharmacyId"
                    component={PharmacyPage}
                    auth={auth}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        />
      </Router>
    </ApolloProvider>
  );
};

export default MainApp;

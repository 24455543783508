import React from "react";
import { StaffRegisterView } from "../components/RegisterView";
import { useTranslation } from 'react-i18next';

const RegisterNewStaffPage = () => {

  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('register_nurse.topic')}</h1>
      <div className="half-panel">
        <StaffRegisterView />
      </div>
    </div>

  )  
}

export default RegisterNewStaffPage;

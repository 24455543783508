import React, { useCallback, useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import { useStore } from "../store";
import { useQuery, useMutation } from "@apollo/client";
import STAFFS_QUERY, {
  REMOVE_STAFF_FROM_PATIENT,
  REMOVE_STAFF_FROM_MEDICINES,
  DELETE_STAFFS,
} from "../queries/staffs";

import { useTranslation } from 'react-i18next';



const StaffListFC = () => {
  // Set page number inside zustand store
  const pageStaff = useStore(state => state.pageStaff);
  const setPageStaff = useStore(state => state.setPageStaff);
  const tableUpdated = useStore(state => state.tableUpdated);
  const setTableUpdated = useStore(state => state.setTableUpdated);

  const [staffsData, setStaffsData] = useState([]);
  const [searchString, setSearchString] = useState('');

  const {loading, data, refetch} = useQuery(STAFFS_QUERY);

  const { t } = useTranslation();

  const columns = [
    {
      Header: <b>{t('nurses_list.table.name_surname')}</b>,
      accessor: "name"
    },
    {
      Header: <b>{t('nurses_list.table.telephone')}</b>,
      accessor: "phoneNumber"
    },
    {
      Header: <b>{t('nurses_list.table.email_address')}</b>,
      accessor: "email"
    },
    {
      Header: <b>{t('nurses_list.table.edit')}</b>,
      accessor: "edit",
      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
      Header: <b>{t('nurses_list.table.remove')}</b>,
      accessor: "remove",
      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
  ];
  
  // refetch after adding
  useEffect(() => {
    if (!tableUpdated) {
      setTableUpdated(true);
      refetch()
    }
  }, [tableUpdated, setTableUpdated, refetch]);

  const [removeStaff] = useMutation(DELETE_STAFFS, {
    refetchQueries: [
      { query: STAFFS_QUERY },
    ],
    awaitRefetchQueries: true,
  });

  const [removeStaffFromPatients] = useMutation(REMOVE_STAFF_FROM_PATIENT);
  const [removeStaffFromMedicines] = useMutation(REMOVE_STAFF_FROM_MEDICINES);

  const onRemoveClick = useCallback(async (id) => {
    try {
      await removeStaffFromPatients({
        variables: { staffId: id },
      });

      await removeStaffFromMedicines({
        variables: { staffId: id },
      });

      await removeStaff({
        variables: {
          staff_id: id,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [removeStaff, removeStaffFromPatients, removeStaffFromMedicines]);

  const onSearchFocus = (e) => {
    setPageStaff(0)
  }

  const onSearch = (e) => {
    setSearchString(e.target.value)
  }

  const onPageChange = (newPage) => {
    setPageStaff(newPage)
  }

  useEffect(() => {
    if (!loading && data && data.staffs) {
      
      // sort by hnId
      var data_staffs = JSON.parse(JSON.stringify(data.staffs));
      data_staffs.sort((a, b) => (a.id > b.id) ? 1 : -1)
      
      const staffs = data_staffs.map(staff => {
        // mutable object from immutable object
        // const unfrozenStaff = JSON.parse(JSON.stringify(staff));
        staff.edit = (
          <Link
            to={`/staff/${staff.id}`}
            style={{ color: "#55d", textDecoration: "none" }}
            state={pageStaff}
          >
            {t('nurses_list.table.edit')}
          </Link>
        );
        staff.remove = (
          <button
            style={{ textDecoration: "none", backgroundColor: 'white', borderWidth: 0, fontSize: 15, color: '#FF1493', cursor: 'pointer' }}
            onClick={() => onRemoveClick(staff.id)}
          >
            {t('nurses_list.table.remove')}
          </button>
        );
        return staff;
      });
      setStaffsData(staffs)
    }
  }, [loading, data, pageStaff, onRemoveClick, t])

  const getFilteredData = useCallback(() => {
    if (searchString) {
      let lowerSearchString = searchString.toLowerCase()
      return staffsData.filter(row => {
        return (
          row.name?.toLowerCase().includes(lowerSearchString) ||
          row.phoneNumber?.toLowerCase().includes(lowerSearchString) ||
          row.email?.toLowerCase().includes(lowerSearchString)
        );
      });
    } else {
      return staffsData
    }
  }, [searchString, staffsData])

  return (
    <div>
      <div className="head-topic">
        <div style={{ display: "flex", alignItems: "center" }}>
          {t('nurses_list.search')}:{"  "}
          <input
            className="searchbox"
            value={searchString}
            onFocus={onSearchFocus}
            onChange={onSearch}
          />
        </div>
        <div style={{flex:1}}/>
        <Link
          className="add-patient"
          to={`/staff_registeration`}
          style={{ textDecoration: "none" }}
        >
          {t('nurses_list.add_nurse')}
        </Link>
      </div>
      <ReactTable
        data={getFilteredData()}
        columns={columns}
        defaultPageSize={10}
        autoResetPage={false}
        className="-striped -highlight"
        page={pageStaff}  
        onPageChange={onPageChange}
        style={{
          // background: '#F0F8FF',
          borderRadius: '20px',
          overflow: 'hidden',
          padding: '20px'
        }}
      />
    </div>
  );
}

export default StaffListFC;

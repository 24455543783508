import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useStore } from "../store";
import { useHistory, Link } from "react-router-dom";

import { Grid, Stack, IconButton } from '@mui/material';
import { Edit, Delete, ImageNotSupported } from '@mui/icons-material';

import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client"

const PHARMACY_QUERY = gql`
  query pharmacy {
    pharmacy {
      id
      pillName
      pillType
      thumbnail
      by_superuser
    }
  }
`


// hospital_id

const DELETE_PHARMACY = gql`
mutation deletePharmacy($pharmacy_id: uuid!) {
  delete_pharmacy(where: {id: {_eq: $pharmacy_id}}) {
    returning {
      id
    }
  }
}
`

const PillPlaceHolder = () => {
  return (
    <div style={{
      width: 200,
      height: 200,
      backgroundColor: '#ccc',
      color: 'white',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: "center"
    }}>
      <Stack style={{ display: 'flex', alignItems: 'center' }}>
        <ImageNotSupported sx={{ fontSize: 80 }} />
        No Image Added
      </Stack>
    </div>
  )
}

const App = () => {

  const { t } = useTranslation();

  const tableUpdated = useStore(state => state.tableUpdated);
  const setTableUpdated = useStore(state => state.setTableUpdated);

  const [pharmacyData, setPharmacyData] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [filteredType, setFilteredType] = useState('all');

  const onSearch = (e) => {
    setSearchString(e.target.value)
  }

  const onFilter = (e) => {
    setFilteredType(e.target.value);
  }

  const history = useHistory();

  const [removePharmacy] = useMutation(DELETE_PHARMACY, {
    refetchQueries: [
      { query: PHARMACY_QUERY },
    ],
    awaitRefetchQueries: true,
  });

  const { loading, data, refetch } = useQuery(PHARMACY_QUERY);

  // refetch after add or edit
  useEffect(() => {
    if (!tableUpdated) {
      setTableUpdated(true);
      refetch()
    }
  }, [tableUpdated, setTableUpdated, refetch]);

  useEffect(() => {
    if (!loading && data && data.pharmacy) {

      // sort
      var data_pharmacy = JSON.parse(JSON.stringify(data.pharmacy));
      data_pharmacy.sort((a, b) => (a.pillName < b.pillName) ? 1 : -1);
      data_pharmacy.sort((a, b) => (a.pillType > b.pillType) ? 1 : -1);
      data_pharmacy.sort((a, b) => (a.by_superuser) ? -1 : 1);
      data_pharmacy.sort((a, b) => {
        if (a.by_superuser === b.by_superuser && a.by_superuser) {
          return -1
        }
        if (a.by_superuser) {
          return -1;
        }
        return 1;
      });

      // filter
      data_pharmacy = data_pharmacy.filter(row => {
        if (filteredType === 'tb' && row.pillType !== 'วัณโรค') {
          return false
        }
        else if (filteredType === 'drtb' && row.pillType !== 'วัณโรคดื้อยา') {
          return false
        }
        return row.pillName.toLowerCase().includes(searchString.toLowerCase())
      });
      setPharmacyData(data_pharmacy);
    }
  }, [loading, data, searchString, filteredType]);

  const onRemoveClick = useCallback((id) => {
    removePharmacy({
      variables: {
        pharmacy_id: id,
      },
    });
  }, [removePharmacy])

  return (
    <div id="main">

      <div style={{
        backgroundColor: 'white',
        borderColor: 'white',
        borderBlockWidth: 0,
        fontSize: 30,
        padding: 10,
        fontFamily: 'Kanit',
      }}>
        {t('pharmacy_list.topic')}
      </div>
      <div style={{ padding: 10 }} />

      <div className="head-topic">
        <div style={{
          display: "flex",
          alignItems: "center"
        }}>
          <div style={{ width: 60 }}>
            {t('pharmacy_list.search')}:
          </div>
          <div>
            <input
              value={searchString}
              onChange={onSearch}
              style={{
                height: 40,
                marginBottom: 0,
              }}
            />
          </div>
          <div style={{ width: 20 }} />

          <div style={{ width: 60, paddingLeft: 5 }}>
            {t('pharmacy_list.table.pill_type')}:
          </div>
          <div style={{
            border: '1px solid #cfcfcf',
            padding: 5,
          }}>
            <select
              style={{
                backgroundColor: 'white',
                borderColor: 'white',
                borderBlockWidth: 0,
                borderBlockStartWidth: 0,
                borderInlineStartWidth: 0,
                fontSize: 16,
                padding: 0,
                fontFamily: 'Kanit',
                height: 30,
              }}
              value={filteredType}
              onChange={onFilter}
            >
              <option value="all">{t('pharmacy_list.pill_type.all')}</option>
              <option value="tb">{t('pharmacy_list.pill_type.tb')}</option>
              <option value="drtb">{t('pharmacy_list.pill_type.drtb')}</option>
            </select>

          </div>
        </div>

        <div style={{ flex: 1 }} />
        <Link
          className="add-patient"
          to={`/pharmacy_registeration`}
          style={{ textDecoration: "none" }}
        >
          {t('pharmacy_list.add_pharmacy')}
        </Link>
      </div>
      <div style={{ height: 10 }} />

      <div className="video-panel"
        style={{ alignItems: 'stretch' }}
      >
        {pharmacyData.map((item, index) => {

          var pill = {};
          if (item.pillName.endsWith(" mg")) {
            pill.name = item.pillName.split(' ').slice(0, -2).join(' ');
            pill.dose = item.pillName.split(' ').slice(-2).join(' ');
          } else {
            pill.name = item.pillName;
            pill.dose = '';
          }
          pill.type = item.pillType;
          pill.by_admin = item.by_superuser;

          return (
            <div className="video-wrapper"
              style={{
                margin: 5,
                width: 202,
                backgroundColor: pill.by_admin ? '#0db0eb' : 'white',
                color: pill.by_admin ? 'white' : '#7d7d7d',
              }}
              key={index}
            >
              <div className="video-panel"
                style={{
                  border: '0px white solid'
                }}
              >
                {item.thumbnail
                  ? <img
                    src={item.thumbnail}
                    alt=""
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: ' center',
                      width: 200,
                      height: 200,
                    }}
                  />
                  : <PillPlaceHolder />
                }
                {pill.by_admin &&
                  <div
                    style={{
                      height: 200,
                      width: 200,
                      position: 'absolute',
                      border: '8px solid #0db0eb',
                    }}
                  />
                }
                {pill.by_admin &&
                  <div
                    style={{
                      height: 200,
                      width: 200,
                      position: 'absolute',
                      border: '8px solid #0db0eb',
                      borderRadius: 25
                    }}
                  />
                }
                {pill.by_admin &&
                  <div
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '5px',
                      zIndex: 1,
                      backgroundColor: '#0db0eb',
                      color: 'white',
                      padding: 5,
                      borderBottomLeftRadius: 10,
                    }}
                  >
                    CCIT
                  </div>
                }
              </div>

              <Grid container
                style={{ padding: 5 }}>
                <Grid item xs={pill.by_admin ? 12 : 8}>
                  <Stack>
                    <div style={{ width: '100%' }}>
                      {pill.name}
                    </div>
                    <div style={{ width: '100%' }}>
                      {pill.dose}
                    </div>
                    <div style={{ width: '100%' }}>
                      {t('pharmacy_list.table.pill_type')} {item.pillType}
                    </div>
                  </Stack >
                </Grid>
                {
                  !pill.by_admin &&
                  <Grid item xs={4}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: "center"
                    }}>
                    <IconButton color="primary" onClick={() => history.push(`/pharmacy/${item.id}`)} >
                      <Edit />
                    </IconButton>
                    <IconButton color="error" onClick={() => onRemoveClick(item.id)} >
                      <Delete />
                    </IconButton>
                  </Grid>
                }
              </Grid>

            </div>
          )
        })}
      </div>

    </div>
  )
}


export default App;

import React from "react";
import PatientList from "../components/PatientList";
import NurseList from "../components/NurseList";
import MedicineList from "../components/MedicineList";
import { useStore } from "../store";

const App = () => {

  const tableName = useStore(state => state.tableName);
  const setTableName = useStore(state => state.setTableName);

  const handleTableChange = (event) => {
    setTableName(event.target.value);
  }

  return (
    <div id="main">

      <select
        id="tbType" 
        style={{
          backgroundColor: 'white', 
          borderColor:'white',
          borderBlockWidth: 0,
          fontSize: 30,
          padding: 10,
          fontFamily: 'Kanit',
        }}
        value={tableName}
        onChange={handleTableChange}
      >
        <option value="patients">รายชื่อผู้ป่วย</option>
        <option value="staffs">รายชื่อพยาบาล</option>
        <option value="medicines">รายการยา</option>
      </select>
      <div style={{padding: 10}} />

      {tableName === 'patients' &&
        <PatientList />
      }
      {tableName === 'staffs' &&
        <NurseList />
      }
      {tableName === 'medicines' &&
        <MedicineList />
      }
    </div>
  )
}


export default App;

import React, { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";

import Messages from "../components/Chat/Messages";
import Input from "../components/Chat/Input";
import MESSAGES_QUERY from "../queries/messages";
import SEND_MESSAGE from "../queries/sendMessage";
import MESSAGE_ADDED_SUBSCRIPTION from "../queries/messageAdded";
import PATIENT_QUERY from "../queries/patient";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../firebase/use-auth";
import READ_PATIENT_MSG from "../queries/readPatientMsg";
import { useTranslation } from "react-i18next";

function ChatPage(props) {
  const { t } = useTranslation();

  const [topic, setTopic] = useState("แชทกับผู้ป่วย");
  const match = useRouteMatch("/chat/:patientId");
  const {
    loading: loadingPatientData,
    error,
    data: patientData,
  } = useQuery(PATIENT_QUERY, {
    variables: {
      id: match.params.patientId,
    },
  });

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  useEffect(() => {
    if (!loadingPatientData && patientData) {
      let patient = patientData.patients_by_pk;
      let text = t("chat.chat_with_patient") + " : ";
      text += patient.name + " ";
      if (patient.surname) text += patient.surname + " ";
      if (patient.age) text += "( " + patient.age + " )";
      setTopic(text);
    } else {
      setTopic(t("chat.chat_with_patient"));
    }
  }, [patientData, loadingPatientData, t]);

  const auth = useAuth();
  const {
    state: { patientFBId },
  } = useLocation();
  const { data, loading, subscribeToMore } = useQuery(MESSAGES_QUERY, {
    variables: { patient_id: patientFBId },
  });
  // const { subscribeToMore } = useQuery(MESSAGES_PATIENT_QUERY, { variables:
  //         { patient_id: patientFBId, from: "staff" }})

  // To turn off unread notification
  const [readPatientMsg] = useMutation(READ_PATIENT_MSG);
  const [patientId, setPatientsId] = useState();
  useEffect(() => {
    if (!loadingPatientData && patientData) {
      setPatientsId(patientData.patients_by_pk.id);
    }
    return () => {
      if (patientId) {
        readPatientMsg({
          variables: { id: patientId },
        });
      }
    };
  }, [loadingPatientData, patientData, readPatientMsg, patientId]);

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    refetchQueries: [
      {
        query: MESSAGES_QUERY,
        variables: { patient_id: patientFBId },
      },
    ],
    awaitRefetchQueries: true,
  });

  // const { data: subData } = useSubscription(MESSAGE_ADDED_SUBSCRIPTION, {
  //     variables: { patientId: patientFBId, from: "staff" }
  // })

  const onSendMessage = async (text) => {
    if (text) {
      try {
        await sendMessage({
          variables: {
            from: "staff",
            text: text,
            patient_id: patientFBId,
            hospital_id: auth.user?.uid,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const more = () => {
    subscribeToMore({
      document: MESSAGE_ADDED_SUBSCRIPTION,
      variables: {
        patientId: patientFBId,
        from: "patient",
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newMessage = subscriptionData.data.messages;
        if (newMessage?.length) {
          const isAlreadyInCache =
            prev.messages.filter((m) => m.id === newMessage[0].id).length > 0;
          if (isAlreadyInCache) {
            return prev;
          }
          return Object.assign({}, prev, {
            messages: [...newMessage, ...prev.messages],
          });
        } else {
          return prev;
        }
      },
    });
  };

  return loading || !data?.messages ? null : (
    <div id="main">
      <div className="head-topic">
        <h1 style={{ whiteSpace: "nowrap" }}>{topic}</h1>
      </div>
      <div className="half-panel">
        <Messages messages={data.messages} subscribeToMore={more} />
        <Input onSendMessage={onSendMessage} />
      </div>
    </div>
  );
}

export default ChatPage;

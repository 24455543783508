/* eslint-disable @typescript-eslint/no-unused-vars */
// Hook (use-auth.js)
import React, { useContext, useEffect, createContext } from "react";
import { toast } from "react-toastify";
import { initializeApp, FirebaseError } from "firebase/app";
import {
  getAuth,
  setPersistence,
  indexedDBLocalPersistence,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail as sendPasswordResetEmailFB,
  confirmPasswordReset as confirmPasswordResetFB,
  reauthenticateWithCredential,
  User,
  EmailAuthProvider,
  sendEmailVerification as sendEmailVerificationFB,
} from "firebase/auth";
import {
  useAuthState,
  useUpdateEmail,
  useUpdatePassword,
  useUpdateProfile,
} from "react-firebase-hooks/auth";

import config from "./firebaseConfig";

interface FirebaseAuthContextValue {
  user: User | null | undefined;
  loading: boolean;
  error: Error | undefined;
  signin: (email: string, password: string) => Promise<User | undefined>;
  signup: (email: string, password: string) => Promise<User | undefined>;
  signout: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<boolean | undefined>;
  sendEmailVerification: (user: User) => Promise<boolean | undefined>;
  confirmPasswordReset: (
    code: string,
    password: string
  ) => Promise<boolean | undefined>;
  updateEmail: (email: string) => Promise<boolean | undefined>;
  updatePassword: (
    email: string,
    old_password: string,
    password: string
  ) => Promise<boolean | undefined>;
  updateProfile: (photoURL: string) => Promise<boolean | undefined>;
}

export const firebase = initializeApp(config);
export const authentication = getAuth(firebase);

const authContext = createContext<FirebaseAuthContextValue>(
  {} as FirebaseAuthContextValue
);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const AuthProvider: React.FC<any> = ({ children }) => {
  const [user, loading, error] = useAuthState(authentication);
  const [updateEmailFunction, updatingEmail, errorEmail] =
    useUpdateEmail(authentication);
  const [updatePasswordFunction, updatingPassword, errorPassword] =
    useUpdatePassword(authentication);
  const [updateProfileFunction, updatingProfile, errorProfile] =
    useUpdateProfile(authentication);

  // useEffect(() => {
  //   authentication.onIdTokenChanged(async (user) => {
  //     if (user) {
  //       try {
  //         const result = await user.getIdTokenResult();
  //         if (result.claims["https://hasura.io/jwt/claims"]) {
  //           // setIsUserSignedIn(true);
  //           await client.resetStore();
  //         } else {
  //           console.log("refresh token")
  //           const endpoint =
  //             "https://us-central1-tbvdot-abfce.cloudfunctions.net/refreshTokenHospital";
  //           fetch(`${endpoint}?uid=${user.uid}`).then(async (res) => {
  //             if (res.status === 200) {
  //               await user.getIdToken(true);
  //               // setIsUserSignedIn(true);
  //               await client.resetStore();
  //             } else {
  //               await signout();
  //               res.json().then((e) => {
  //                 throw e;
  //               });
  //             }
  //           });
  //         }
  //       } catch (e) {
  //         await signout();
  //         console.log(e);
  //       }
  //     } else {
  //       await signout();
  //     }
  //   });
  // }, []);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email: string, password: string) => {
    try {
      console.log("signin");
      await setPersistence(authentication, indexedDBLocalPersistence);
      const response = await signInWithEmailAndPassword(
        authentication,
        email,
        password
      );
      return response.user;
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };

  const signup = async (email: string, password: string) => {
    try {
      await setPersistence(authentication, indexedDBLocalPersistence);
      const response = await createUserWithEmailAndPassword(
        authentication,
        email,
        password
      );
      toast.success("User Registered Successfully");
      return response.user;
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case "auth/email-already-in-use":
            toast.error(error.message);
            break;
          case "auth/invalid-email":
            toast.error(error.message);
            break;
          case "auth/weak-password":
            toast.error(error.message);
            break;
          default:
            break;
        }
      }
    }
  };

  const signout = async () => {
    try {
      await signOut(authentication);
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };

  const sendPasswordResetEmail = async (email: string) => {
    try {
      await sendPasswordResetEmailFB(authentication, email);
      return true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };

  const sendEmailVerification = async (user: User) => {
    try {
      await sendEmailVerificationFB(user);
      return true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };

  const confirmPasswordReset = async (code: string, password: string) => {
    try {
      await confirmPasswordResetFB(authentication, code, password);
      return true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };

  const updateEmail = async (email: string) => {
    try {
      await updateEmailFunction(email);
      return true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };

  const updatePassword = async (
    email: string,
    old_password: string,
    password: string
  ) => {
    try {
      let credential = EmailAuthProvider.credential(email, old_password);
      if (user) {
        await reauthenticateWithCredential(user, credential);
        await updatePasswordFunction(password);
      } else {
        toast.error("Invalid E-mail or Old Password.");
        return false;
      }

      return true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };

  const updateProfile = async (photoURL: string) => {
    try {
      await updateProfileFunction({ photoURL });
      return true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast.error(error.message);
      }
    }
  };
  return (
    <authContext.Provider
      value={{
        user,
        loading,
        error,
        signin,
        signup,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        updateEmail,
        updatePassword,
        updateProfile,
        sendEmailVerification,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

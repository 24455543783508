import React from "react";
import { PatientRegisterView } from "../components/RegisterView";
import { useTranslation } from 'react-i18next';

const RegisterNewPatientPage = () => {

  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('register_patient.topic')}</h1>
      <div className="half-panel">
        <PatientRegisterView />
      </div>
    </div>
  )
}

export default RegisterNewPatientPage;

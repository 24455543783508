// import "./RegisterView.css";
import React, { useRef } from "react";
import { FormikProvider, Form, Field, useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStore } from "../../store";
import { useTranslation } from 'react-i18next';

import { gql } from "@apollo/client"

const UPDATE_STAFF = gql`
mutation updateStaff(
  $id: uuid!,
  $name: String!,
  $phoneNumber: String,
  $email: String,
  ) {
  update_staffs_by_pk(
    pk_columns: {id: $id},
    _set: {
      name: $name,
      phoneNumber: $phoneNumber,
      email: $email,
    }) {
    id
  }
}`;

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("กรุณากรอกชื่อ"),
  phoneNumber: Yup.string().matches(new RegExp('[0-9]{7}'), 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง').required("กรุณากรอกเบอร์โทรศัพท์"),
  email: Yup.string().email('รูปแบบอีเมลไม่ถูกต้อง').required("กรุณากรอกที่อยู่อีเมล"),
});

const StaffInformationView = ({ staff }) => {

  const { t } = useTranslation();

  const drop = useRef(null);

  const [updateStaff] = useMutation(UPDATE_STAFF);
  const setTableUpdated = useStore(state => state.setTableUpdated);
  const history = useHistory();

  const initialValues = {
    email: staff.email,
    name: staff.name,
    phoneNumber: staff.phoneNumber,
  };

  const onSubmit = async (values) => {
    try {

      await updateStaff({
        variables: {
          id: staff?.id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
        },
      });

      setSubmitting(false);
      setTableUpdated(false);
      history.goBack();
    } catch (error) {
      toast(error.message, { type: "error" });
      setSubmitting(false);
      setErrors(error);
      window.alert(
        "[เกิดข้อผิดพลาด] ข้อมูลไม่ถูกต้องหรือข้อมูลนี้มีอยู่แล้วในระบบ โปรดตรวจสอบ !"
      );
      resetForm();
    }
  };

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues,
    onSubmit,
  });

  const {
    setSubmitting,
    setErrors,
    resetForm,
    isSubmitting,
    handleSubmit,
    errors,
  } = formik;

  return (
    <div className="app" ref={drop}>
      <ToastContainer />
      <FormikProvider value={formik}>
        <Form>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('nurse_information.form.name_surname')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.name}</div>
            </span>
            <Field name="name" placeholder="" />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('nurse_information.form.telephone')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.phoneNumber}</div>
            </span>
            <Field name="phoneNumber" placeholder="" />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('nurse_information.form.email')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.email}</div>
            </span>
            <Field name="email" placeholder="" />
          </label>

          <button
            className="mybutton"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting
              ? t('nurse_information.submit.loading')
              : t('nurse_information.submit.edit')
            }
          </button>

        </Form>
      </FormikProvider>
    </div>
  );
};

export default StaffInformationView;

import { gql } from "@apollo/client"

// export default gql`
//   query patients {
//     patients {
//       age
//       citizenId
//       created_at
//       hnId
//       hospital
//       hospital_id
//       id
//       result
//       firebase_id
//       ifLastImage
//       ifLastMessage
//       ifUnreadMessage
//       name
//       surname
//       phoneNumber
//       healthConditions
//       passport
//       staff_id
//       gender
//       tbType
//       medicines {
//         hospital_id
//         id
//         patient_id
//         pillAmount
//         pillName
//         pillType
//         staff_id
//         startDate
//         stopDate
//       }
//       files {
//         bucket_id
//         created_at
//         dateStatus
//         filename
//         id
//         mimeType
//         patient_id
//         hospital_id
//         status
//         takenAt
//       }
//     }
//   }
// `

export default gql`
  query patients {
    patients {
      age
      citizenId
      created_at
      hnId
      hospital
      hospital_id
      id
      result
      firebase_id
      ifLastImage
      ifLastMessage
      ifUnreadMessage
      name
      surname
      phoneNumber
      healthConditions
      passport
      staff_id
      gender
      tbType
    }
  }
`

export const PATIENTS_ID_QUERY =  gql`
  query patients {
    patients {
      citizenId
      passport
    }
  }
`

import { gql } from '@apollo/client'

export default gql`
  query contents {
    files(where: {patient_id: {_is_null: true}}) {
      bucket_id
      filename
      hospital_id
      id
      mimeType
    }
  }
`;

export const INSERT_CONTENTS_MUTATION = gql`
    mutation upsertContents($insert_files_input: [files_insert_input!]!) {
        insert_files(objects: $insert_files_input, on_conflict: {constraint: files_pkey, update_columns: []}) {
            affected_rows
        }
    }
`

import React, { useCallback, useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import { useQuery, useMutation } from "@apollo/client";

import PATIENTS_QUERY from "../queries/patients";
// import READ_PATIENT_IMG from "../queries/readPatientImg";
import READ_PATIENT_MSG from "../queries/readPatientMsg";
import ALL_FILE_ADDED_SUBSCRIPTION from "../queries/allFileAdded";
import ALL_MESSAGE_ADDED_SUBSCRIPTION from "../queries/allMessageAdded";
import { useTranslation } from 'react-i18next';

import { useStore } from "../store";

const PatientListFC = () => {
  // Set page number inside zustand store
  const pagePatient = useStore(state => state.pagePatient)
  const setPagePatient = useStore(state => state.setPagePatient)
  const tableUpdated = useStore(state => state.tableUpdated);
  const setTableUpdated = useStore(state => state.setTableUpdated);

  const [patientsData, setPatientsData] = useState([]);
  const [searchString, setSearchString] = useState('');

  const {loading, data, refetch, subscribeToMore} = useQuery(PATIENTS_QUERY,
    // {options: {fetchPolicy: 'network-only'}}
  );
  // const [readPatientImg] = useMutation(READ_PATIENT_IMG);
  const [readPatientMsg] = useMutation(READ_PATIENT_MSG);

  const { t } = useTranslation();

  const columns = [
    {
      Header: <b>{t('patients_list.table.citizen_id')}</b>,
      accessor: "citizenId"
    },
    {
      Header: <b>{t('patients_list.table.hospital_number')}</b>,
      accessor: "hnId"
    },
    {
      Header: <b>{t('patients_list.table.name')}</b>,
      accessor: "name"
    },
    {
      Header: <b>{t('patients_list.table.surname')}</b>,
      accessor: "surname"
    },
    {
      Header: <b>{t('patients_list.table.treatment')}</b>,
      accessor: "result",
      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
      Header: <b>{t('patients_list.table.DOT')}</b>,
      accessor: "link",
      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
      Header: <b>{t('patients_list.table.chat')}</b>,
      accessor: "chat",
      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    }
  ];

  // refetch after adding
  useEffect(() => {
    if (!tableUpdated) {
      setTableUpdated(true);
      refetch()
    }
  }, [tableUpdated, refetch, setTableUpdated]);

  const onDotClick = useCallback((id) => {
    // readPatientImg({ variables: { id }, 
    // })
    // .then( () => refetch() )
  }, [])

  const onChatClick = useCallback((id) => {
    readPatientMsg({ variables: { id }, 
    })
    .then( () => refetch() )
  }, [readPatientMsg, refetch])

  const onSearchFocus = (e) => {
    setPagePatient(0)
  }

  const onSearch = (e) => {
    setSearchString(e.target.value)
  }

  const onPageChange = (newPage) => {
    setPagePatient(newPage)
  }

  useEffect(() => {
    if (!loading && data) {
      subscribeToMore({
        document: ALL_FILE_ADDED_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          refetch()
          // Have to wait for a moment and refetchagain
          setTimeout(()=> {
            refetch()
          }, 6000)
          if (!subscriptionData.data) return prev;
        },
        update: ()=> {
        }
      });
    }
    }, [loading, data, refetch, subscribeToMore])

    useEffect(() => {
      if (!loading && data) {
        subscribeToMore({
          document: ALL_MESSAGE_ADDED_SUBSCRIPTION,
          updateQuery: (prev, { subscriptionData }) => {
            refetch()
            // Have to wait for a moment and refetchagain
            setTimeout(()=> {
              refetch()
            }, 6000)
            if (!subscriptionData.data) return prev;
          },
          update: ()=> {
          }
        });
      }
      }, [data, loading, refetch, subscribeToMore])

  useEffect(() => {
    if (!loading && data && data.patients) {
      
      // sort by hnId
      var data_patients = JSON.parse(JSON.stringify(data.patients));
      data_patients.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1)
      data_patients.sort((a, b) => (a.ifLastImage > b.ifLastImage) ? 1 : -1)
      data_patients.sort((a, b) => (a.ifLastMessage < b.ifLastMessage) ? 1 : -1)
      
      const patients = data_patients.map(patient => {
        // mutable object from immutable object
        const unfrozenPatient = JSON.parse(JSON.stringify(patient));
        unfrozenPatient.link = (
          <Link
            to={`/patient/${patient.id}`}
            style={{ 
              color: patient.ifLastImage ? "#fca954" : "#777",
              textDecoration: "none" 
            }}
            state={pagePatient}
            onClick={() => onDotClick(patient.id)}
          >
            click
          </Link>
        );
        unfrozenPatient.chat = (
          <Link
            to={{ pathname: `/chat/${patient.id}`, state: { patientFBId: patient.firebase_id } }}
            style={{
              color: patient.ifLastMessage ? "#ff0000" : "#777",
              textDecoration: "none"
            }}
            onClick={() => onChatClick(patient.id)}
          >
            chat
          </Link>
        );
        return unfrozenPatient;
      });
      setPatientsData(patients)
    }
  }, [pagePatient, data, loading, onChatClick, onDotClick])

  const getFilteredData = useCallback(() => {
    if (searchString) {
      let lowerSearchString = searchString.toLowerCase()
      return patientsData.filter(row => {
        return (
          row.citizenId?.includes(lowerSearchString) ||
          row.hnId.toLowerCase().includes(lowerSearchString) ||
          row.name.toLowerCase().includes(lowerSearchString) || 
          row.surname?.toLowerCase().includes(lowerSearchString) || 
          row.result?.toLowerCase().includes(lowerSearchString)
        );
      });
    } else {
      return patientsData
    }
  }, [searchString, patientsData])

  return (
    <div>
      <div className="head-topic">
        <div style={{ display: "flex", alignItems: "center" }}>
          {t('patients_list.search')}:{"  "}
          <input
            className="searchbox"
            value={searchString}
            onFocus={onSearchFocus}
            onChange={onSearch}
          />
        </div>
        <div style={{flex:1}}/>
        <Link
          className="add-patient"
          to={`/patient_registeration`}
          style={{ textDecoration: "none" }}
        >
          {t('patients_list.add_patient')}
        </Link>
      </div>
      <ReactTable
        data={getFilteredData()}
        columns={columns}
        defaultPageSize={10}
        autoResetPage={false}
        className="-striped -highlight"
        page={pagePatient}  
        onPageChange={onPageChange}
        style={{
          // background: '#F0F8FF',
          borderRadius: '20px',
          overflow: 'hidden',
          padding: '20px'
        }}
      />
    </div>
  );
}

// export default compose(
//   graphql(PATIENTS_QUERY),
//   graphql(READ_PATIENT_MSG, { name: "readPatientMsg" })
// )(PatientListFC);

export default PatientListFC;

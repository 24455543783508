import React from "react";
import "./App.css";
import { useStore } from "./app/store";
import { AuthProvider } from "./firebase/use-auth";
import "./app/utilities/i18n";

import MainApp from "./app/main";

const App = () => {
  useStore();

  return (
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  );
};

export default App;

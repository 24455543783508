import { gql } from "@apollo/client"

export default gql`
  mutation updatePatientFile($id: uuid!, $status: String, $dateStatus: String) {
    update_files_by_pk(pk_columns: {id: $id}, _set: {status: $status, dateStatus: $dateStatus}) {
      id
    }
  }
`;

export const INSERT_PATIENT_FILES_MUTATION = gql`
    mutation upsertPatientFiles($insert_files_input: [files_insert_input!]!) {
        insert_files(objects: $insert_files_input, on_conflict: {constraint: files_pkey, update_columns: [status, dateStatus]}) {
            affected_rows
        }
    }
`



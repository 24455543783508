import React from "react";
import { useMutation, } from "@apollo/client";
import REMOVE_CONTENT_MUTATION from "../queries/deleteContent";
import QUERY_CONTENTS from "../queries/contents";


const ContentItem = ({ src, id, type, fileName, by_superuser }) => {
  const [removeContent] = useMutation(REMOVE_CONTENT_MUTATION, {
    refetchQueries: [
      {
        query: QUERY_CONTENTS
      },
    ],
    awaitRefetchQueries: true,
  });

  let content;
  if (type === "image/jpg" || type === "image/jpeg") {
    content = <img style={{ width: "250px" }} src={src} alt="รูป/วิดีโอ" />;
  } else if (type === "image/png") {
    content = <img style={{ width: "250px" }} src={src} alt="รูป/วิดีโอ" />;
  } else if (type === "video/mp4") {
    content = <div className="video-wrapper">
      <video
        // ref="vidRef"
        style={{ width: "240px", height: "240px" }}
        controls="controls autoplay"
        autoPlay={false}
        src={src}
        type={"video/mp4"}
      />
    </div>;
  }
  return (
    <div className="video-wrapper">
      {by_superuser &&
        <div
          style={{
            border: '8px solid #0db0eb',
            height: '100%',
            width: '100%',
            position: 'absolute',
            borderRadius: 25
          }}
        />
      }
      {by_superuser &&
        <div
          style={{
            border: '8px solid #0db0eb',
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
        />
      }
      <div className="video-panel">{content}</div>
      {!by_superuser &&
        <div>
          <button
            className="overlay-button-del"
            onClick={async () => {
              try {
                const { data: {
                  removeObject: {
                    success,
                  },
                  delete_files: {
                    affected_rows
                  }
                } } = await removeContent({
                  variables: {
                    bucketId: id,
                    fileName
                  }
                }
                );
                if (success && affected_rows) {
                  window.alert("File successfully deleted!")
                } else {
                  window.alert("File deletion failed!")
                }
              } catch (e) {
                console.log(e);
              }
            }}
          >
            delete
          </button>
        </div>
      }
      {by_superuser &&
        <div
          style={{
            position: 'absolute',
            right: '5px',
            top: '5px',
            zIndex: 1,
            backgroundColor: '#0db0eb',
            color: 'white',
            padding: 5,
            borderBottomLeftRadius: 10,
          }}
        >
          CCIT
        </div>
      }
    </div>
  );
};

export default ContentItem;

import { gql } from "@apollo/client";

const ALL_FILE_ADDED_SUBSCRIPTION = gql`
    subscription allMessageAdded {
        files {
            patient_id
        }
    }
`;

export default ALL_FILE_ADDED_SUBSCRIPTION;

import React, { Fragment, useEffect, useState } from "react";
import PATIENT_QUERY from "../queries/patient";
import DotCalendar from "../components/DotCalendar";
import { useQuery } from "@apollo/client";
import { PatientInformation } from "../components/InformationView";
import { useHistory, useRouteMatch } from "react-router-dom"
import { useTranslation } from 'react-i18next';


const PatientFC = () => {
  
  const { t } = useTranslation();

  const [patient, setPatient] = useState(null);
  const history = useHistory()
  const match = useRouteMatch('/patient/:patientId')
  const { loading, error, data: patientData } = useQuery(PATIENT_QUERY, {
    variables: {
      id: match.params.patientId
    }
  });

  const onShowVideo = (day, patient_id) => {
    history.push({
        pathname: `/uploads/${match.params.patientId}/${day.split('T')[0]}`,
        state: { patient_id }
    });
  };

  useEffect(() => {
    if (patientData) {
      setPatient(patientData.patients_by_pk);
    }
  }, [patientData]);

  if (patient && !loading) {
    return (
      <Fragment>
            <h1>{t('patient_infomation.topic')}</h1>
            <div className="panel">
              <div className="half-panel">
                <PatientInformation patient={patient} />
              </div>
              <div className="half-panel">
                <DotCalendar
                  files={patient.files}
                  patient_id={patient.firebase_id}
                  onShowVideo={onShowVideo}
                />
              </div>
            </div>
      </Fragment>)
  } else if (error) {
    return <div>Error...{error.message}</div>
  } else  {
    return <div>Loading...</div>
  }
}

export default PatientFC;

import React, { useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../firebase/use-auth";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("It should be an email")
    .required("This field is required"),
  password: Yup.string().required("This field is required"),
});

const LoginPage = () => {
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      history.push("/");
    }
  }, [auth.user, history]);

  const signinHandler = async (values) => {
    try {
      const { email, password } = values;
      const user = await auth.signin(email, password);

      if (!user?.emailVerified) {
        await auth.sendEmailVerification(user);
        await auth.signout();
        window.alert("Please verify your email.");
      } else {
        localStorage.setItem("signed_in", "1");
        history.push("/");
      }
    } catch (error) {
      localStorage.setItem("signed_in", "0");
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundImage: `url("/pexels-jeshootscom-576831.jpg")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          padding: 40,
          paddingTop: 80,
          paddingBottom: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          borderRadius: 50,
        }}
      >
        <img src="/vdot.ico" alt="" width={150} height={150} />
        <div
          style={{
            fontSize: 25,
            color: "black",
            fontWeight: "bold",
            padding: 30,
          }}
        >
          กรุณาเข้าสู่ระบบ
        </div>
        <div
          style={{
            backgroundColor: "white",
            padding: 0,
            width: 360,
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={signinHandler}
          >
            {({
              values,
              touched,
              errors,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
              isInitialValid,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={!!(errors.email && touched.email)}
                  helperText={errors.email && touched.email && errors.email}
                  margin="normal"
                />
                <TextField
                  label="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  fullWidth
                  error={!!(errors.password && touched.password)}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  margin="normal"
                />
                <div style={{ height: 25 }} />

                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!isValid || !!isInitialValid}
                >
                  Sign In
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

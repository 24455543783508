import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const App = () => {
  const history = useHistory();

  useEffect(() => {
    history.push({pathname: `/patients`});  
  });
  
  return (
    <div id="main"/>
  )
}


export default App;

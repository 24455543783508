import { gql } from "@apollo/client"


export default gql`
    query hospital {
        hospitals {
            email
            firebase_id
            name
            phoneNumber
            id
        }
    }
`
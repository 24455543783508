import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { INSERT_PATIENT_FILES_MUTATION } from "../queries/updateFilesStatus";
import PATIENT_VIDEO_QUERY from "../queries/patientsDateVideos";
import PATIENT_QUERY from "../queries/patient";
import READ_PATIENT_IMG from "../queries/readPatientImg";
import { useHistory, useRouteMatch } from "react-router-dom";
import { addHours } from "date-fns";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../firebase/use-auth";

const VideoStatusUpdateForm = (props) => {
  const { t } = useTranslation();

  const auth = useAuth();
  const history = useHistory();
  const match = useRouteMatch("/uploads/:patientId/:date");
  const [updateFilesStatus] = useMutation(INSERT_PATIENT_FILES_MUTATION, {
    refetchQueries: [
      {
        query: PATIENT_VIDEO_QUERY,
        variables: {
          patient_id: props.patientFBId,
          // ASSUME EVERYONE UPLOADS FROM THAILAND TIME
          time_min: addHours(new Date(props.date), -7).toISOString(),
          time_max: addHours(new Date(props.date), 17).toISOString(),
        },
      },
      {
        query: PATIENT_QUERY,
        variables: { id: props.patientId },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [state, setState] = useState({
    dateStatus: "",
    approveStatus: [],
    selectedDate: "",
    dateArray: [],
    dateIndex: "",
  });

  useEffect(() => {
    const dateArr = JSON.parse(localStorage.getItem("dateArray"));
    const selDate = match.params.date;
    const dateIndex = dateArr.indexOf(selDate);
    setState((s) => ({
      ...s,
      selectedDate: selDate,
      dateArray: dateArr,
      dateIndex,
    }));
  }, [match.params.date]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      dateStatus: props.dateStatus,
      approveStatus: props.approveStatus,
    }));
  }, [props.dateStatus, props.approveStatus]);

  const handleVideoStatusChange = (changeEvent) => {
    // create new approve array from img & vdo
    const vdoIndex = props.videoType.flatMap((type, i) =>
      type === "video/mp4" ? state.approveStatus[i] : []
    );
    const imgIndex = props.videoType.flatMap((type, i) =>
      type === "image/jpeg" ? state.approveStatus[i] : []
    );

    // approve condition => 1 image pass & 1 vdo pass
    if (imgIndex.length !== 0) {
      if (vdoIndex.length !== 0) {
        if (imgIndex.includes("approved") && vdoIndex.includes("approved")) {
          setState({
            ...state,
            dateStatus: "approved",
          });
        } else {
          setState({
            ...state,
            dateStatus: "denied",
          });
        }
      } else {
        setState({
          ...state,
          dateStatus: "denied",
        });
      }
    } else {
      setState({
        ...state,
        dateStatus: "denied",
      });
    }
  };

  const navigateToDate = (date, patientId) => {
    history.push({
      pathname: `/uploads/${patientId}/${date}`,
      state: { patient_id: props.patientFBId },
    });
  };

  const [readPatientImg] = useMutation(READ_PATIENT_IMG);

  const onSubmitForm = async () => {
    const updatedFiles = _.zip(props.videos, props.approveStatus).map(
      (item) => ({
        id: item[0].id,
        bucket_id: item[0].bucket_id,
        filename: item[0].fileName,
        dateStatus: state.dateStatus,
        status: item[1],
        mimeType: item[0].mimeType,
        patient_id: props.patientFBId,
        hospital_id: auth.user?.uid,
      })
    );
    try {
      await updateFilesStatus({
        variables: {
          insert_files_input: updatedFiles,
        },
        onCompleted: () => {
          if (!props.approveStatus.includes(null)) {
            readPatientImg({ variables: { id: match.params.patientId } });
          }
          window.alert("บันทึกข้อมูลสำเร็จ");
          history.push(`/patient/${props.patientId}`);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form>
      <div>
        <button
          className="mybutton"
          type="button"
          onMouseOver={handleVideoStatusChange}
          onClick={onSubmitForm}
        >
          {t("patient_videos.save")}
        </button>
        <button
          className="mybutton outline"
          type="button"
          onClick={() => history.push(`/patient/${props.patientId}`)}
        >
          {t("patient_videos.back_to_calendar")} &#8634;
        </button>

        <button
          className="mybutton outline"
          type="button"
          disabled={state.dateIndex <= 0}
          onClick={() => {
            var i = state.dateIndex - 1 <= 0 ? 0 : state.dateIndex - 1;
            localStorage.setItem("selectedDate", state.dateArray[i]);
            setState({ ...state, selectedDate: state.dateArray[i] });
            navigateToDate(state.dateArray[i], props.patientId);
          }}
        >
          &#8592; {t("patient_videos.the_previous_day")}
        </button>

        <button
          className="mybutton outline"
          type="button"
          disabled={state.dateIndex >= state.dateArray.length - 1}
          onClick={() => {
            var i =
              state.dateIndex + 1 >= state.dateArray.length - 1
                ? state.dateArray.length - 1
                : state.dateIndex + 1;
            localStorage.setItem("selectedDate", state.dateArray[i]);
            // context.update({ selectedDate: state.dateArray[i] })
            setState({ ...state, selectedDate: state.dateArray[i] });
            navigateToDate(state.dateArray[i], props.patientId);
          }}
        >
          {t("patient_videos.the_next_day")} &#8594;
        </button>
      </div>
    </form>
  );
};

export default VideoStatusUpdateForm;

import gql from 'graphql-tag'

export default gql`
mutation signUpPatient($age: Int!,
  $birthdate: date,
  $citizenId: String!,
  $email: String!,
  $firebase_id: String!,
  $gender: String,
  $healthConditions: String,
  $hnId: String!,
  $hospital: String,
  $hospital_id: String!,
  $name: String!,
  $passport: String,
  $password: String!,
  $phoneNumber: String,
  $result: String,
  $staff_id: uuid,
  $surname: String,
  $tbType: String) {
  insert_patients_one(object: {
    age: $age,
    birthdate: $birthdate,
    citizenId: $citizenId,
    email: $email,
    firebase_id: $firebase_id,
    gender: $gender,
    healthConditions: $healthConditions,
    hnId: $hnId,
    hospital: $hospital,
    hospital_id: $hospital_id,
    name: $name,
    passport: $passport,
    password: $password,
    phoneNumber: $phoneNumber,
    result: $result,
    staff_id: $staff_id,
    surname: $surname,
    tbType: $tbType}) {
    id
  }
}
`
export const SIGNUP_PATIENT_FB_MUTATION = gql`mutation singUpPatientFB(
  $displayName: String!, $email: String!, $password: String!) {
  signUpPatient(credentials: {displayName: $displayName, email: $email, password: $password}) {
    id
  }
}`
import { gql } from "@apollo/client";

const ALL_MESSAGE_ADDED_SUBSCRIPTION = gql`
    subscription allMessageAdded {
        messages {
            patient_id
        }
    }
`;

export default ALL_MESSAGE_ADDED_SUBSCRIPTION;

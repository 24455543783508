import React, { Component } from 'react';
import dateFns, { addHours } from "date-fns"
// import { utcToZonedTime } from 'date-fns-tz'
import './DotCalendar.css'


class DotCalendar extends Component {
  constructor(props) {
    super(props);
    this.approvedDateArray = []
    this.deniedDateArray = []
    this.pendingDateArray = []

    this.props.files.forEach( date =>{

      // add 7 hours to takenAt date to display calendar correctly
      const dateTaken = addHours(date.takenAt, 7).toISOString()

      if (date.dateStatus ===""){     // check from each fileStatus on same date
        
        if(date.status==="approved"){
          this.approvedDateArray.push(dateTaken.split('T')[0])
        }
        else if(date.status==="denied"){
          this.deniedDateArray.push(dateTaken.split('T')[0])
        }
        else {
          this.pendingDateArray.push(dateTaken.split('T')[0])
        }
      
      } else {    // check from dateStatus 

        if(date.dateStatus==="approved"){
          // this.approvedDateArray.push(date.takenAt.split('T')[0])
          this.approvedDateArray.push(dateTaken.split('T')[0])
        }
        else if(date.dateStatus==="denied"){
          // this.deniedDateArray.push(date.takenAt.split('T')[0])
          this.deniedDateArray.push(dateTaken.split('T')[0])
        }
        else {
          // this.pendingDateArray.push(date.takenAt.split('T')[0])
          this.pendingDateArray.push(dateTaken.split('T')[0])
        }
      }
    })
    // this.selectedDateArray = this.props.videoFiles.map(date => date.takenAt.split('T')[0])
    this.selectedDateArray = this.props.files.map(date => addHours(date.takenAt, 7).toISOString().split('T')[0])
    this.state = {
      currentMonth: new Date(),
      selectedDate: new Date(),
    }
    // remove duplicated data and sort date array
    var sdArr = Object.values({...this.selectedDateArray})
    var minArr = sdArr.filter(function(elem, pos) {
      return sdArr.indexOf(elem) === pos;
    });
    this.minArray = minArr.sort()
  }

  componentDidMount() {
    localStorage.setItem('selectedDate', ''); 
    localStorage.setItem('dateArray',  '');
	}

  renderHeader() {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">
            chevron_right
            </div>
        </div>
      </div>
    )
  }

  renderDays() {
    const dateFormat = "dddd";
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      )
    }
    return <div className="days row">{days}</div>;
  }

  renderCells() {
    const { currentMonth } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";
    const queryDateFormat = 'YYYY-MM-DDTHH:MM:SSZ'
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
              !dateFns.isSameMonth(cloneDay, monthStart) // if day out of the range of the month
                ? "disabled"
                : this.deniedDateArray.some(date => dateFns.isSameDay(cloneDay, date)) ? "denied"
                : this.approvedDateArray.some(date => dateFns.isSameDay(cloneDay, date)) ? "approved" 
                : this.pendingDateArray.some(date => dateFns.isSameDay(cloneDay, date)) ? "pending":
                "ordinary"
            }`}
            key={cloneDay}
            onClick={() => this.onDateClick(dateFns.format(cloneDay, queryDateFormat))}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = (day ) => {
    day = day.split('+')[0]
    this.props.onShowVideo(day, this.props.patient_id)
  
    this.setState({
      selectedDate: day
    })

    localStorage.setItem('selectedDate', day.split('T')[0]);
    localStorage.setItem('dateArray', JSON.stringify(this.minArray));
  }
  
  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
    })
  };

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
    })
  };


  render() {
    return (
      <div className='calendar' style={{ width: '100%' }}>
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default DotCalendar
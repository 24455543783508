import { useEffect, useState, useMemo } from "react";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { useAuth } from "./use-auth";

const HTTPS_URL = "https://hasura.tbvdot.com/v1/graphql";
const WSS_URL = "wss://hasura.tbvdot.com/v1/graphql";

// Custom hook to initialize Apollo Client
export const useApolloClient = () => {
  const { user } = useAuth(); // useAuth hook to get the current user
  const [token, setToken] = useState("");

  useEffect(() => {
    let isCancelled = false;

    const getToken = async () => {
      if (user) {
        const newToken = await user.getIdToken();
        if (!isCancelled) {
          setToken(newToken);
        }
      }
    };

    getToken();

    return () => {
      isCancelled = true;
    };
  }, [user]);

  const client = useMemo(() => {
    return new ApolloClient({
      link: createLink(token),
      cache: new InMemoryCache(),
    });
  }, [token]); // Only reinitialize client when token changes

  return client;
};

const createLink = (token) => {
  const httpLink = new HttpLink({
    uri: HTTPS_URL,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      if (networkError.message === "Network request failed") {
        console.log("ไม่สามารถเชื่อมต่อกับระบบ กรุณาลองใหม่ภายหลัง");
      }
      console.log(
        `Network error: ${networkError.message} Code:${networkError.statusCode}`
      );
    }
  });

  const authLink = setContext((_, { headers }) => {
    // Use the token passed to the function
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const httpWithAuth = authLink.concat(httpLink);

  const wsLink = new WebSocketLink({
    uri: WSS_URL,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        };
      },
    },
  });

  return ApolloLink.from([
    errorLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpWithAuth
    ),
  ]);
};

import { gql } from "@apollo/client"

const READ_PATIENT_MSG = gql`
  mutation readPatientMsg($id: uuid!) {
    update_patients_by_pk(pk_columns: {id: $id}, _set: {ifLastMessage: false}) {
      ifLastMessage
    }
  }
`;

export default READ_PATIENT_MSG;

// import "./RegisterView.css";
import React, { useEffect, useRef, useState } from "react";
import { FormikProvider, Form, Field, FieldArray, useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useStore } from "../../store";

import { useAuth } from "../../../firebase/use-auth";
import SIGNUP_PATIENT_MUTATION from "../../queries/signUpPatient";
import { SIGNUP_PATIENT_FB_MUTATION } from "../../queries/signUpPatient";
import { UPDATE_PATIENT_MEDICINES_MUTATION } from "../../queries/updatePatient";
import { CREATE_BUCKET_MUTATION } from "../../queries/storage";
import { PATIENTS_ID_QUERY } from "../../queries/patients";
import STAFFS_QUERY from "../../queries/staffs";
import { gql } from "@apollo/client";
// import listPillTB from "../../../tb_pills.json";
// import listPillTBX from "../../../tb_pills2.json";
import pillTypes from "../../../tb_pillTypes.json";
import { useTranslation } from "react-i18next";

const PHARMACY_QUERY = gql`
  query pharmacy {
    pharmacy {
      id
      pillName
      pillType
      thumbnail
    }
  }
`;

const HOSPITAL_QUERY = gql`
  query hospitals {
    hospitals {
      name
      shortName
      firebase_id
      id
    }
  }
`;

const SignupSchema = Yup.object().shape({
  citizenId: Yup.string()
    .min(13, "กรุณากรอกหมายเลขบัตรประจำตัวประชาชน")
    .max(13, "กรุณากรอกหมายเลขบัตรประจำตัวประชาชน"),
  hnId: Yup.string().required("กรุณากรอกรหัส HN"),
  name: Yup.string().required("กรุณากรอกชื่อ"),
});
const initialValues = {
  citizenId: "",
  passport: "",
  hnId: "",
  email: "",
  password: "",
  name: "",
  surname: "",
  hospital: "",
  result: "On treatment",
  phoneNumber: "",
  age: 20,
  gender: "male",
  healthConditions: "",
  tbType: "",
  medicines: [],
  staffIndex: 0,
  staff: { name: "", phoneNumber: "", email: "" },
};

const emptyStaff = {
  id: null,
  email: "",
  name: "NONE",
  phoneNumber: "",
};

const getHnidPostfix = (hnId) => {
  if (hnId) {
    if (hnId.length > 5) {
      return hnId.substring(hnId.length - 5);
    } else {
      return hnId;
    }
  }
};

const RegisterForm = (props) => {
  const { t } = useTranslation();

  const setTableUpdated = useStore((state) => state.setTableUpdated);
  const history = useHistory();

  const [open, setOpen] = useState({});
  const [staffs, setStaffs] = useState([emptyStaff]);
  const auth = useAuth();
  const drop = useRef(null);
  const { data: patientsIds } = useQuery(PATIENTS_ID_QUERY);
  const [signUpPatientFB] = useMutation(SIGNUP_PATIENT_FB_MUTATION);
  const [signUpPatient] = useMutation(SIGNUP_PATIENT_MUTATION);
  const [updatePatientMedicines] = useMutation(
    UPDATE_PATIENT_MEDICINES_MUTATION
  );
  const [createBucket] = useMutation(CREATE_BUCKET_MUTATION);
  const {
    data: staffData,
    loading: staffsLoading,
    error: staffsError,
  } = useQuery(STAFFS_QUERY);
  const {
    data: pharmacyData,
    loading: pharmacyLoading,
    error: pharmacyError,
  } = useQuery(PHARMACY_QUERY);
  const [showPills, setShowPills] = useState([]);

  function pillClick(id) {
    // setOpen({ ...open, [id]: true });
    setOpen({ [id]: !open[id] });
  }

  useEffect(() => {
    return () => setOpen({});
  }, []);

  const [hospitalName, setHospitalName] = useState("");
  const [hospitalShortName, setHospitalShortName] = useState("");

  const {
    data: hospitalData,
    loading: hospitalLoading,
    error: hospitalError,
  } = useQuery(HOSPITAL_QUERY, {
    options: { fetchPolicy: "network-only" },
  });

  useEffect(() => {
    if (hospitalData && !hospitalLoading && !hospitalError) {
      setHospitalName(hospitalData.hospitals[0].name);
      setHospitalShortName(hospitalData.hospitals[0].shortName);
    }
  }, [hospitalData, hospitalLoading, hospitalError]);

  const onSubmit = async (values) => {
    const email = `${values.hnId}${hospitalShortName}@tbvdot.com`;
    const password = `${hospitalShortName.toLowerCase()}${getHnidPostfix(
      values.hnId
    )}`;

    try {
      if (values.citizenId === "" && values.passport === "") {
        throw new Error(
          "กรุณากรอกหมายเลขบัตรประจำตัวประชาชนหรือหมายเลขพาสปอร์ต"
        );
      }

      const staffId = staffs[values.staffIndex]?.id;
      const newPassport =
        typeof values.passport === "string"
          ? values.passport.replace(/\s+/g, "").toLowerCase()
          : "";

      // TODO: CHECK IF CITIZENID OR PASSPORT IS ALREADY EXIST
      if (patientsIds?.patients?.length) {
        patientsIds.patients.forEach(({ citizenId, passport }) => {
          const passportIndb =
            typeof passport === "string"
              ? passport.replace(/\s+/g, "").toLowerCase()
              : "";
          if (citizenId === values.citizenId && citizenId !== "") {
            throw new Error("เลขบัตรประจำตัวประชาชนหรือหมายเลขพาสปอร์ตซ้ำ");
          } else if (passportIndb === newPassport && passportIndb !== "") {
            throw new Error("เลขบัตรประจำตัวประชาชนหรือหมายเลขพาสปอร์ตซ้ำ");
          }
        });
      }

      // Sign up patient with email and password for firebase
      const {
        data: {
          signUpPatient: { id: firebaseId },
        },
      } = await signUpPatientFB({
        variables: {
          email,
          password,
          displayName: values.name,
        },
      });
      // Sign up patient with email and password for graphql
      const {
        data: {
          insert_patients_one: { id: graphqlId },
        },
      } = await signUpPatient({
        variables: {
          citizenId: values.citizenId,
          passport: newPassport,
          hnId: values.hnId,
          email: email,
          password: password,
          firebase_id: firebaseId,
          name: values.name,
          surname: values.surname,
          hospital: hospitalName,
          hospital_id: auth.user?.uid,
          result: values.result,
          phoneNumber: values.phoneNumber,
          age: values.age,
          gender: values.gender,
          healthConditions: values.healthConditions,
          tbType: values.tbType,
          staff_id: staffId,
        },
      });

      // dont update medicines table if no pill added
      if (values.medicines.length > 0) {
        const medicines = values.medicines.map(
          ({ pillAmount, pillName, pharmacy_id }) => ({
            pillAmount,
            pillName,
            staff_id: staffId,
            hospital_id: auth.user?.uid,
            patient_id: firebaseId,
            pharmacy_id,
          })
        );

        // Update or create patient's medicines
        await updatePatientMedicines({
          variables: {
            patient_id: firebaseId,
            medicines,
          },
        });
      }

      // Create bucket for patient
      await createBucket({
        variables: {
          bucketId: graphqlId,
        },
      });
      setSubmitting(false);
      setTableUpdated(false);
      history.goBack();
    } catch (error) {
      console.log("error", error);
      toast(error.message, { type: "error" });
      setSubmitting(false);
      setErrors(error);
      window.alert(
        "[เกิดข้อผิดพลาด] ข้อมูลไม่ถูกต้องหรือข้อมูลนี้มีอยู่แล้วในระบบ โปรดตรวจสอบ !"
      );
      resetForm();
    }
  };

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues,
    onSubmit,
  });

  const {
    setSubmitting,
    setErrors,
    resetForm,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setValues,
    values,
    errors,
  } = formik;

  const [pillOrder, setPillOrder] = useState([]);
  const [pillLibrary, setPillLibrary] = useState({});

  useEffect(() => {
    if (pharmacyData && !pharmacyError && !pharmacyLoading) {
      var library = {};
      for (let i = 0; i < pharmacyData.pharmacy.length; i++) {
        var p = pharmacyData.pharmacy[i];
        library[p.id] = p;
      }
      setPillLibrary(library);
    }
  }, [pharmacyData, pharmacyError, pharmacyLoading]);

  useEffect(() => {
    if (pharmacyData && !pharmacyError && !pharmacyLoading) {
      // sort type of pills
      var types = [];
      if (values.tbType) {
        types.push(values.tbType);
      }
      for (let i = 0; i < pillTypes.length; i++) {
        if (!types.includes(pillTypes[i])) {
          types.push(pillTypes[i]);
        }
      }

      // assign pill from database to order
      var order = [];
      for (let i = 0; i < types.length; i++) {
        var _pillType = types[i];
        var _pillList = [];
        let data_pharmacy = [...pharmacyData.pharmacy];
        data_pharmacy.sort((a, b) => (a.pillName > b.pillName ? 1 : -1));
        for (let j = 0; j < data_pharmacy.length; j++) {
          let p = data_pharmacy[j];
          if (_pillType === p.pillType) {
            _pillList.push(p.id);
          }
        }
        order.push({
          pillType: _pillType,
          pillList: _pillList,
        });
      }

      // // assign pill from plain text 1 to order
      // _pillList = [];
      // for (let j = 0; j < listPillTB.length; j++) {
      //   _pillList.push(listPillTB[j]);
      // }
      // order.push({
      //   pillType: "รายการเพิ่มเติม 1",
      //   pillList: _pillList
      // })

      // // assign pill from plain text 2 to order
      // _pillList = [];
      // for (let j = 0; j < listPillTBX.length; j++) {
      //   _pillList.push(listPillTBX[j]);
      // }
      // order.push({
      //   pillType: "รายการเพิ่มเติม 2",
      //   pillList: _pillList
      // })

      setPillOrder(order);
    }
  }, [values.tbType, pharmacyData, pharmacyError, pharmacyLoading]);

  const handleShowPillsClick = (index) => {
    if (showPills.includes(index)) {
      setShowPills(showPills.filter((e) => e !== index));
    } else {
      setShowPills(showPills.concat([index]));
    }
  };

  const handlePillRemove = (index) => {
    var pills = [...showPills];
    if (showPills.includes(index)) {
      pills = pills.filter((e) => e !== index);
    }
    for (var i = 0; i < pills.length; i++) {
      if (pills[i] > index) {
        pills[i] = pills[i] - 1;
      }
    }
    setShowPills(pills);
  };

  useEffect(() => {
    if (staffData && !staffsError && !staffsLoading) {
      setStaffs([
        emptyStaff,
        ...staffData.staffs.map(({ id, email, name, phoneNumber }) => ({
          id,
          email,
          name,
          phoneNumber,
        })),
      ]);
    }
  }, [staffData, staffsLoading, staffsError]);

  useEffect(() => {
    setFieldValue(
      "staff.name",
      staffs[values.staffIndex] ? staffs[values.staffIndex].name : ""
    );
    setFieldValue(
      "staff.phoneNumber",
      staffs[values.staffIndex] ? staffs[values.staffIndex].phoneNumber : ""
    );
    setFieldValue(
      "staff.email",
      staffs[values.staffIndex] ? staffs[values.staffIndex].email : ""
    );
  }, [setFieldValue, values.staffIndex, staffs]);

  return (
    <div className="app" ref={drop}>
      <ToastContainer />
      <FormikProvider value={formik}>
        <Form>
          <label>
            <span>
              {t("register_patient.form.citizen_id")}
              <div style={{ color: "red" }}>{errors.citizenId}</div>
            </span>
            <Field name="citizenId" placeholder="" />
          </label>

          <label>
            <span>{t("register_patient.form.passport_no")}</span>
            <Field type="text" name="passport" />
          </label>

          <label>
            <span>
              {t("register_patient.form.hn_id")}
              <div style={{ color: "red" }}>{errors.hnId}</div>
            </span>
            <Field name="hnId" placeholder="hn00000_01" />
          </label>

          <fieldset disabled>
            <label>
              <span> {t("register_patient.form.email")} </span>
              <Field
                name="email"
                as="input"
                value={`${values.hnId}${hospitalShortName}@tbvdot.com`}
              />
            </label>
            <label>
              <span> {t("register_patient.form.password")} </span>
              <Field
                name="password"
                value={`${hospitalShortName.toLowerCase()}${getHnidPostfix(
                  values.hnId
                )}`}
              />
            </label>
          </fieldset>

          <label>
            <span>
              {t("register_patient.form.name")}
              <div style={{ color: "red" }}>{errors.name}</div>
            </span>
            <Field type="text" name="name" />
          </label>

          <label>
            <span>{t("register_patient.form.surname")}</span>
            <Field type="text" name="surname" />
          </label>

          <label>
            <span>{t("register_patient.form.hospital")}</span>
            <Field type="text" value={hospitalName} disabled />
          </label>

          <label>
            <span>{t("register_patient.form.telephone")}</span>
            <Field type="text" name="phoneNumber" />
          </label>

          <label>
            <span>{t("register_patient.form.age")}</span>
            <Field type="number" name="age" min="0" max="130" />
          </label>

          <label>
            <span>{t("register_patient.form.gender.topic")}</span>
            <Field className="dropdown" component="select" name="gender">
              <option value="male">
                {t("register_patient.form.gender.male")}
              </option>
              <option value="female">
                {t("register_patient.form.gender.female")}
              </option>
            </Field>
          </label>

          <label>
            <span>{t("register_patient.form.health_conditions")}</span>
            <Field type="text" name="healthConditions" />
          </label>

          <label>
            <span>{t("register_patient.form.diagnosis.topic")}</span>
          </label>
          <div className="radio">
            <label>
              <span>{t("register_patient.form.diagnosis.tuberculosis")}</span>
              <input
                type="radio"
                checked={values.tbType === "วัณโรค"}
                onChange={() => {
                  setValues({ ...values, tbType: "วัณโรค" });
                }}
              />
            </label>
          </div>
          <div className="radio">
            <label>
              <span>
                {t(
                  "register_patient.form.diagnosis.drug_resistant_tuberculosis"
                )}
              </span>
              <input
                type="radio"
                checked={values.tbType === "วัณโรคดื้อยา"}
                onChange={() => {
                  setValues({ ...values, tbType: "วัณโรคดื้อยา" });
                }}
              />
            </label>
          </div>

          {values.tbType ? (
            <div className="seperate-field">
              <label className="title-text">
                {t("register_patient.form.pharmacy.topic")}
              </label>
              <br />
              <FieldArray
                name="medicines"
                render={(arrayHelpers) => (
                  <div>
                    {values.medicines.map((medicine, index) => {
                      var pillName = "";
                      var thumbnail = "";
                      if (
                        Object.keys(pillLibrary).includes(medicine.pharmacy_id)
                      ) {
                        pillName = pillLibrary[medicine.pharmacy_id].pillName;
                        thumbnail = pillLibrary[medicine.pharmacy_id].thumbnail;
                      } else {
                        pillName = medicine.pillName;
                        thumbnail = "";
                      }
                      return (
                        <div
                          key={index}
                          style={{ paddingTop: 10, backgroundColor: "#ccc" }}
                        >
                          <div className="med-container">
                            <Field
                              className="dropdown drug"
                              component="div"
                              style={{ zIndex: 999 - index }}
                              name={`medicines[${index}].pillName`}
                              onClick={() => {
                                pillClick(index);
                              }}
                            >
                              <label>{pillName}</label>

                              {open[index] && (
                                <div className="dropdown-content">
                                  {/* <option value="">{t('register_patient.form.pharmacy.name')}</option> */}

                                  {pillOrder.map((order) => {
                                    return (
                                      <div key={order.pillType}>
                                        <div className="dropdown-divider" />
                                        <div>{order.pillType}</div>
                                        {order.pillList.map((pillId) => {
                                          var pName =
                                            pillLibrary[pillId].pillName;
                                          return (
                                            <div
                                              className="dropdown-item"
                                              key={pillId}
                                              value={pillId}
                                              onClick={() => {
                                                pillClick(index);
                                                setFieldValue(
                                                  `medicines[${index}].pharmacy_id`,
                                                  pillId
                                                );
                                                setFieldValue(
                                                  `medicines[${index}].pillName`,
                                                  pName
                                                );
                                              }}
                                            >
                                              {pName}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </Field>

                            <label>
                              <span>
                                {" "}
                                {t(
                                  "register_patient.form.pharmacy.amount"
                                )}{" "}
                              </span>
                            </label>
                            <Field
                              className="med-amount"
                              type="number"
                              name={`medicines[${index}].pillAmount`}
                              min="0"
                              max="130"
                            />
                            <span>
                              {" "}
                              {t("register_patient.form.pharmacy.unit")}{" "}
                            </span>
                            <button
                              type="button"
                              style={{
                                fontFamily: "Kanit",
                                fontSize: 15,
                                width: 30,
                                margin: 5,
                              }}
                              onClick={() => handleShowPillsClick(index)}
                            >
                              {t("register_patient.form.pharmacy.show")}
                            </button>
                            <button
                              type="button"
                              style={{
                                fontFamily: "Kanit",
                                fontSize: 15,
                                width: 30,
                                marginRight: 5,
                                color: "#FF1493",
                              }}
                              onClick={() => {
                                handlePillRemove(index);
                                arrayHelpers.remove(index);
                              }}
                            >
                              {t("register_patient.form.pharmacy.remove")}
                            </button>
                          </div>
                          {showPills.includes(index) && (
                            <div
                              style={{
                                width: "100%",
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img src={thumbnail} alt="" id={"canvas"} />
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <button
                      className="mybutton outline"
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          pillName: "not selected",
                          pillAmount: 1,
                          pharmacy_id: null,
                        })
                      }
                    >
                      {t("register_patient.form.pharmacy.add_pharmacy")}
                    </button>
                  </div>
                )}
              />
            </div>
          ) : null}

          <label>
            <span>{t("register_patient.form.treatment")}</span>
            <Field className="dropdown" component="select" name="result">
              <option value="On treatment">On treatment</option>
              <option value="Cure">Cure</option>
              <option value="Lost follow up">Lost follow up</option>
              <option value="Transfer out">Transfer out</option>
              <option value="Fail">Fail</option>
              <option value="Change diagnosis">Change diagnosis</option>
              <option value="Dead">Dead</option>
            </Field>
          </label>

          <div id="seperate-field" className="seperate-field">
            <label className="title-text">
              {t("register_patient.form.staff.topic")}
            </label>
            <br />
            <Field className="dropdown" component="select" name="staffIndex">
              {staffs.map((staff, index) => (
                <option key={index} value={index}>
                  {staff.name}
                </option>
              ))}
            </Field>
            <fieldset disabled>
              <label>
                <span>{t("register_patient.form.staff.name_surname")}</span>
                <Field type="text" name="staff.name" />
              </label>

              <label>
                <span>{t("register_patient.form.staff.telephone")}</span>
                <Field type="text" name="staff.phoneNumber" />
              </label>

              <label>
                <span>{t("register_patient.form.staff.email")}</span>
                <Field type="text" name="staff.email" />
              </label>
            </fieldset>
          </div>

          <button
            className="mybutton"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting
              ? t("register_patient.form.submit.loading")
              : t("register_patient.form.submit.register")}
          </button>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default RegisterForm;

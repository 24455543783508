import { gql } from "@apollo/client"

export default gql`
  query patientFiles($patient_id: String!, $time_min: timestamptz!, $time_max: timestamptz!) {
    files(where: {patient_id: {_eq: $patient_id}, _and: {created_at: {_gte: $time_min, _lte: $time_max}}}) {
      bucket_id
      patient_id
      status
      takenAt
      created_at
      mimeType
      id
      hospital_id
      filename
      dateStatus
    }
  }
`
import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import { GET_UPLOAD_LINKS_MUTATION } from "../queries/storage";
import QUERY_HOSPITAL from '../queries/hospital';
import QUERY_CONTENTS, { INSERT_CONTENTS_MUTATION } from "../queries/contents";
import { useTranslation } from 'react-i18next';


// //create your forceUpdate hook
// function useForceUpdate(){
//   const [value, setValue] = useState(0); // integer state
//   return () => setValue(!value); // update the state to force render
// }

const UploadFileList = () => {

    const { t } = useTranslation();

    const [fileList, setFileList] = useState([]);
    const [getUploadLinks] = useMutation(GET_UPLOAD_LINKS_MUTATION);
    const { data, loading } = useQuery(QUERY_HOSPITAL);
    const [insertContents] = useMutation(INSERT_CONTENTS_MUTATION, {
        refetchQueries: [
            {
                query: QUERY_CONTENTS
            }
        ],
        awaitRefetchQueries: true
    })

    const [hospital, setHospital] = useState(null);

    useEffect(() => {
        if (data && !loading) {
            try {
                const { id, name, firebase_id, email, phoneNumber } = data.hospitals[0]
                setHospital({ id, name, firebase_id, email, phoneNumber })
            } catch (e) {
                console.log(e)
            }
        }
    }, [data, loading])

    const upload = (file, url) => {
        return fetch(url, {
            method: 'PUT',
            body: file
        })
    }

    const handleChange = ({target: {validity, files}}) => {
        if (validity.valid) {
            setFileList(files)
        }
    }

    const uploadFiles = async () => {
        if (fileList && hospital) {
            try {
                const fileArray = [...fileList].map(({name, type}) => ({name, type}));
                const {
                    data: {
                        getUploadLinks: {
                            urls
                        }
                    }
                } = await getUploadLinks({
                    variables: {
                        bucketId: hospital.id,
                        fileNames: fileArray.map(({name}) => name)
                    }
                })

                const responses = await Promise.all(urls.map((url, index) => upload(fileList.item(index), url)));

                if (responses.filter(res => res.status !== 200).length > 0) {
                    window.alert("One or more files failed to upload! Please try again!")
                    return false
                }

                const contentInsertions = fileArray.map(({ name, type }) => ({
                    "bucket_id": hospital.id,
                    "filename": name,
                    "mimeType": type,
                    "hospital_id": hospital.firebase_id
                }))

                const {
                    data: {
                        insert_files: {
                            affected_rows
                        }
                    }
                } = await insertContents({
                    variables: {
                        insert_files_input: contentInsertions
                    }
                })

                if (affected_rows < fileArray.length) {
                    window.alert("One or more of the uploaded files have duplicate names. File names must be unique!")
                    return false
                }

                window.alert("All files have been successfully uploaded.")

                return true

            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <form>
            <input
                type="file" accept="image/*,video/*" multiple required onChange={handleChange}/>
            <button
                disabled={fileList.length < 1}
                type="button"
                style={{fontSize: "18px"}}
                // onClick={useForceUpdate}>
                onClick={uploadFiles}>
                {t('tb_information.upload')}
            </button>
        </form>
    );
}

export default UploadFileList;

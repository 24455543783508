import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {addHours} from "date-fns";
import {useLocation, useRouteMatch} from "react-router-dom";

import {GET_DOWNLOAD_LINKS_MUTATION} from "../queries/storage";
import PATIENT_VIDEO_QUERY from "../queries/patientsDateVideos";
import VideoReport from "../components/VideoReport";
import VideoStatusUpdateForm from "../components/VideoStatusUpdateForm";
import _ from "lodash";
import { useTranslation } from 'react-i18next';


const VideoReporterPage = (props) => {

    const { t } = useTranslation();
    
    const [getDownloadLinks] = useMutation(GET_DOWNLOAD_LINKS_MUTATION)
    const [state, setState] = useState({
        submittedVideos: [],
        videoId: [],
        approveStatus: [],
        dateStatus: undefined,
        videoType: []
    });

    const match = useRouteMatch('/uploads/:patientId/:date');
    const location = useLocation();

    const {data, loading, error} = useQuery(PATIENT_VIDEO_QUERY, {
        variables: {
            patient_id: location.state.patient_id,
            time_min: addHours(new Date(match.params.date), -7).toISOString(),
            time_max: addHours(new Date(match.params.date), 17).toISOString()
        }
    });

    useEffect(() => {
        if (data && !loading) {
            if (data.files.length > 0) {
                const files = data.files.map(({ id, status, bucket_id, filename, mimeType }) =>
                    ({ id, bucket_id, filename, mimeType, status }))
                getDownloadLinks({
                    variables: {
                        bucketId: files[0].bucket_id,
                        fileNames: files.map(file => file.filename)
                    },
                }).then(({ data: { getDownloadLinks : { urls }}}) => {
                    if (urls) {
                        const items = _.zip(files, urls).map(item => ({
                            fileName: item[0].filename,
                            mimeType: item[0].mimeType,
                            bucket_id: item[0].bucket_id,
                            id: item[0].id,
                            url: item[1],
                        }))
                        setState(s => ({
                            ...s,
                            submittedVideos: items,
                            videoId: data.files.map(file => file.id),
                            approveStatus: data.files.map(file => file.status),
                            videoType: data.files.map(file => file.mimeType)
                        })
                        )
                    }
                })
                    .catch(error => console.log(error))
            }
        }
    }, [data, loading, getDownloadLinks])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const handler = changeEvent => {
        const index = state.videoId.indexOf(changeEvent.target.id)
        const newStatus = changeEvent.target.value;
        setState({
            ...state, approveStatus: [
                ...state.approveStatus.slice(0, index), newStatus, ...state.approveStatus.slice(index + 1)
            ]
        });
    }

    const showVideo = () => {
        return state.submittedVideos.map((file, index) => {
            return (
                <VideoReport
                    key={file.id}
                    src={file.url}
                    type={file.mimeType}
                    videoId={file.id}
                    index={index}
                    // dateStatus={video.dateStatus}
                    approveItem={state.approveStatus[index]}
                    handler={handler}
                />
            );
        });
    };

    return (
        <div>
            <h1>{t('patient_videos.topic')}</h1>
            <div className="video-panel">{showVideo()}</div>

            <VideoStatusUpdateForm
                videos={state.submittedVideos}
                videoId={state.videoId}
                videoType={state.videoType}
                dateStatus={state.dateStatus}
                approveStatus={state.approveStatus}
                date={match.params.date}
                patientId={match.params.patientId}
                patientFBId={location.state.patient_id}
            />
        </div>
    );

}

export default VideoReporterPage;
import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { StaffInformation } from "../components/InformationView";
import { useRouteMatch } from "react-router-dom"
import { gql } from "@apollo/client";
import { useTranslation } from 'react-i18next';

const STAFFS_QUERY = gql`
  query staff($id:uuid!) {
    staffs_by_pk(id:$id) {
      id
      email
      name
      phoneNumber
    }
  }
`

const StaffFC = () => {

  const { t } = useTranslation();

  const [staff, setStaff] = useState(null);
  const match = useRouteMatch('/staff/:staffId')
  const { loading, error, data: staffData } = useQuery(STAFFS_QUERY, {
    variables: {
      id: match.params.staffId
    }
  });

  useEffect(() => {
    if (staffData) {
      setStaff(staffData.staffs_by_pk);
    }
  }, [staffData]);

  if (staff && !loading) {
    return (
      <Fragment>
        <h1>{t('nurse_information.topic')}</h1>
        <div className="panel">
          <div className="half-panel">
            <StaffInformation staff={staff} />
          </div>
        </div>
      </Fragment>)
  } else if (error) {
    return <div>Error...{error.message}</div>
  } else  {
    return <div>Loading...</div>
  }
}

export default StaffFC;

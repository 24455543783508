import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { PharmacyInformation } from "../components/InformationView";
import { useRouteMatch } from "react-router-dom";
import { gql } from "@apollo/client"
import { useTranslation } from 'react-i18next';

const PHARMACY_QUERY = gql`
  query pharmacy($id:uuid!) {
    pharmacy_by_pk(id:$id) {
      id
      pillName
      pillType
      thumbnail
    }
  }
`

const PharmacyFC = () => {

  const { t } = useTranslation();

  const [pharmacy, setPharmacy] = useState(null);
  const match = useRouteMatch('/pharmacy/:pharmacyId')
  const { loading, error, data: pharmacyData } = useQuery(PHARMACY_QUERY, {
    variables: {
      id: match.params.pharmacyId
    }
  });
  
  useEffect(() => {
    if (pharmacyData) {
      setPharmacy(pharmacyData.pharmacy_by_pk)
    }
  }, [pharmacyData])

  if (pharmacy && !loading) {
    return (
      <Fragment>
            <h1>{t('pharmacy_information.topic')}</h1>
            <div className="panel">
              <div className="half-panel">
                <PharmacyInformation pharmacy={pharmacy} />
              </div>
            </div>
      </Fragment>)
  } else if (error) {
    return <div>Error...{error.message}</div>
  } else  {
    return <div>Loading...</div>
  }
}

export default PharmacyFC;
